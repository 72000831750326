const ExternalDependencies = {
  "@adobe/css-tools@4.1.0": {
    licenses: "MIT",
    repository: "https://github.com/adobe/css-tools",
    publisher: "TJ Holowaychuk",
    email: "tj@vision-media.ca",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@adobe/css-tools",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@adobe/css-tools/LICENSE",
  },
  "@babel/code-frame@7.18.6": {
    licenses: "MIT",
    repository: "https://github.com/babel/babel",
    publisher: "The Babel Team",
    url: "https://babel.dev/team",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/code-frame",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/code-frame/LICENSE",
  },
  "@babel/helper-validator-identifier@7.19.1": {
    licenses: "MIT",
    repository: "https://github.com/babel/babel",
    publisher: "The Babel Team",
    url: "https://babel.dev/team",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/helper-validator-identifier",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/helper-validator-identifier/LICENSE",
  },
  "@babel/highlight@7.18.6": {
    licenses: "MIT",
    repository: "https://github.com/babel/babel",
    publisher: "The Babel Team",
    url: "https://babel.dev/team",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/highlight",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/highlight/LICENSE",
  },
  "@babel/runtime@7.20.13": {
    licenses: "MIT",
    repository: "https://github.com/babel/babel",
    publisher: "The Babel Team",
    url: "https://babel.dev/team",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/runtime",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@babel/runtime/LICENSE",
  },
  "@popperjs/core@2.11.6": {
    licenses: "MIT",
    repository: "https://github.com/popperjs/popper-core",
    publisher: "Federico Zivolo",
    email: "federico.zivolo@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@popperjs/core",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@popperjs/core/LICENSE.md",
  },
  "@react-aria/ssr@3.4.1": {
    licenses: "Apache-2.0",
    repository: "https://github.com/adobe/react-spectrum",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@react-aria/ssr",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@react-aria/ssr/LICENSE",
  },
  "@react-leaflet/core@2.1.0": {
    licenses: "Hippocratic-2.1",
    repository: "https://github.com/PaulLeCam/react-leaflet",
    publisher: "Paul Le Cam",
    email: "paul@ulem.net",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@react-leaflet/core",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@react-leaflet/core/LICENSE.md",
  },
  "@remix-run/router@1.3.1": {
    licenses: "MIT",
    repository: "https://github.com/remix-run/react-router",
    publisher: "Remix Software",
    email: "hello@remix.run",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@remix-run/router",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@remix-run/router/LICENSE.md",
  },
  "@restart/hooks@0.4.8": {
    licenses: "MIT",
    repository: "https://github.com/jquense/react-common-hooks",
    publisher: "Jason Quense",
    email: "monastic.panic@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@restart/hooks",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@restart/hooks/LICENSE",
  },
  "@restart/ui@1.5.4": {
    licenses: "MIT",
    repository: "https://github.com/react-restart/ui",
    publisher: "Jason Quense",
    email: "monastic.panic@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@restart/ui",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@restart/ui/LICENSE",
  },
  "@swc/helpers@0.4.14": {
    licenses: "MIT",
    repository: "https://github.com/swc-project/swc",
    publisher: "강동윤",
    email: "kdy1997.dev@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@swc/helpers",
  },
  "@testing-library/dom@8.20.0": {
    licenses: "MIT",
    repository: "https://github.com/testing-library/dom-testing-library",
    publisher: "Kent C. Dodds",
    email: "me@kentcdodds.com",
    url: "https://kentcdodds.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/dom/LICENSE",
  },
  "@testing-library/jest-dom@5.16.5": {
    licenses: "MIT",
    repository: "https://github.com/testing-library/jest-dom",
    publisher: "Ernesto Garcia",
    email: "gnapse@gmail.com",
    url: "http://gnapse.github.io",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/jest-dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/jest-dom/LICENSE",
  },
  "@testing-library/react@13.4.0": {
    licenses: "MIT",
    repository: "https://github.com/testing-library/react-testing-library",
    publisher: "Kent C. Dodds",
    email: "me@kentcdodds.com",
    url: "https://kentcdodds.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/react",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/react/LICENSE",
  },
  "@testing-library/user-event@13.5.0": {
    licenses: "MIT",
    repository: "https://github.com/testing-library/user-event",
    publisher: "Giorgio Polvara",
    email: "polvara@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/user-event",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/user-event/LICENSE",
  },
  "@types/aria-query@5.0.1": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/aria-query",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/aria-query/LICENSE",
  },
  "@types/geojson@7946.0.10": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/geojson",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/geojson/LICENSE",
  },
  "@types/jest@27.5.2": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/jest",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/jest/LICENSE",
  },
  "@types/js-cookie@2.2.7": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/js-cookie",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/js-cookie/LICENSE",
  },
  "@types/leaflet@1.9.0": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/leaflet",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/leaflet/LICENSE",
  },
  "@types/node@16.18.11": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/node",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/node/LICENSE",
  },
  "@types/prop-types@15.7.5": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/prop-types",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/prop-types/LICENSE",
  },
  "@types/react-bootstrap-typeahead@5.1.8": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-bootstrap-typeahead",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-bootstrap-typeahead/LICENSE",
  },
  "@types/react-dom@18.0.10": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-dom/LICENSE",
  },
  "@types/react-transition-group@4.4.5": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-transition-group",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react-transition-group/LICENSE",
  },
  "@types/react@18.0.27": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/react/LICENSE",
  },
  "@types/scheduler@0.16.2": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/scheduler",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/scheduler/LICENSE",
  },
  "@types/testing-library__jest-dom@5.14.5": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/testing-library__jest-dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/testing-library__jest-dom/LICENSE",
  },
  "@types/warning@3.0.0": {
    licenses: "MIT",
    repository: "https://github.com/DefinitelyTyped/DefinitelyTyped",
    publisher: "Chi Vinh Le",
    email: "https://github.com/cvle",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/warning",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@types/warning/README.md",
  },
  "@xobotyi/scrollbar-width@1.9.5": {
    licenses: "MIT",
    repository: "https://github.com/xobotyi/scrollbar-width",
    publisher: "Anton Zinovyev",
    email: "xog3@yandex.ru",
    url: "https://github.com/xobotyi",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@xobotyi/scrollbar-width",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@xobotyi/scrollbar-width/LICENSE",
  },
  "ansi-regex@5.0.1": {
    licenses: "MIT",
    repository: "https://github.com/chalk/ansi-regex",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ansi-regex",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ansi-regex/license",
  },
  "ansi-styles@3.2.1": {
    licenses: "MIT",
    repository: "https://github.com/chalk/ansi-styles",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ansi-styles",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ansi-styles/license",
  },
  "ansi-styles@4.3.0": {
    licenses: "MIT",
    repository: "https://github.com/chalk/ansi-styles",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/ansi-styles",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/ansi-styles/license",
  },
  "ansi-styles@5.2.0": {
    licenses: "MIT",
    repository: "https://github.com/chalk/ansi-styles",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "https://sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/pretty-format/node_modules/ansi-styles",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/pretty-format/node_modules/ansi-styles/license",
  },
  "aria-query@5.1.3": {
    licenses: "Apache-2.0",
    repository: "https://github.com/A11yance/aria-query",
    publisher: "Jesse Beach",
    email: "splendidnoise@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/aria-query",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/aria-query/LICENSE",
  },
  "asynckit@0.4.0": {
    licenses: "MIT",
    repository: "https://github.com/alexindigo/asynckit",
    publisher: "Alex Indigo",
    email: "iam@alexindigo.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/asynckit",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/asynckit/LICENSE",
  },
  "available-typed-arrays@1.0.5": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/available-typed-arrays",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/available-typed-arrays",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/available-typed-arrays/LICENSE",
  },
  "axios@1.3.2": {
    licenses: "MIT",
    repository: "https://github.com/axios/axios",
    publisher: "Matt Zabriskie",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/axios",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/axios/LICENSE",
  },
  "bootstrap@5.2.3": {
    licenses: "MIT",
    repository: "https://github.com/twbs/bootstrap",
    publisher: "The Bootstrap Authors",
    url: "https://github.com/twbs/bootstrap/graphs/contributors",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/bootstrap",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/bootstrap/LICENSE",
  },
  "call-bind@1.0.2": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/call-bind",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/call-bind",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/call-bind/LICENSE",
  },
  "chalk@2.4.2": {
    licenses: "MIT",
    repository: "https://github.com/chalk/chalk",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/chalk",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/chalk/license",
  },
  "chalk@3.0.0": {
    licenses: "MIT",
    repository: "https://github.com/chalk/chalk",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/jest-dom/node_modules/chalk",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/@testing-library/jest-dom/node_modules/chalk/license",
  },
  "chalk@4.1.2": {
    licenses: "MIT",
    repository: "https://github.com/chalk/chalk",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/chalk",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/chalk/license",
  },
  "classnames@2.3.2": {
    licenses: "MIT",
    repository: "https://github.com/JedWatson/classnames",
    publisher: "Jed Watson",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/classnames",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/classnames/LICENSE",
  },
  "color-convert@1.9.3": {
    licenses: "MIT",
    repository: "https://github.com/Qix-/color-convert",
    publisher: "Heather Arthur",
    email: "fayearthur@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/color-convert",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/color-convert/LICENSE",
  },
  "color-convert@2.0.1": {
    licenses: "MIT",
    repository: "https://github.com/Qix-/color-convert",
    publisher: "Heather Arthur",
    email: "fayearthur@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/color-convert",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/color-convert/LICENSE",
  },
  "color-name@1.1.3": {
    licenses: "MIT",
    repository: "https://github.com/dfcreative/color-name",
    publisher: "DY",
    email: "dfcreative@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/color-name",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/color-name/LICENSE",
  },
  "color-name@1.1.4": {
    licenses: "MIT",
    repository: "https://github.com/colorjs/color-name",
    publisher: "DY",
    email: "dfcreative@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/color-name",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/color-name/LICENSE",
  },
  "combined-stream@1.0.8": {
    licenses: "MIT",
    repository: "https://github.com/felixge/node-combined-stream",
    publisher: "Felix Geisendörfer",
    email: "felix@debuggable.com",
    url: "http://debuggable.com/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/combined-stream",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/combined-stream/License",
  },
  "compute-scroll-into-view@1.0.20": {
    licenses: "MIT",
    repository: "https://github.com/scroll-into-view/compute-scroll-into-view",
    publisher: "Cody Olsen",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/compute-scroll-into-view",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/compute-scroll-into-view/LICENSE",
  },
  "copy-to-clipboard@3.3.3": {
    licenses: "MIT",
    repository: "https://github.com/sudodoki/copy-to-clipboard",
    publisher: "sudodoki",
    email: "smd.deluzion@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/copy-to-clipboard",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/copy-to-clipboard/LICENSE",
  },
  "css-in-js-utils@3.1.0": {
    licenses: "MIT",
    repository: "https://github.com/robinweser/css-in-js-utils",
    publisher: "robinweser",
    email: "contact@weser.io",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/css-in-js-utils",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/css-in-js-utils/LICENSE",
  },
  "css-tree@1.1.3": {
    licenses: "MIT",
    repository: "https://github.com/csstree/csstree",
    publisher: "Roman Dvornov",
    email: "rdvornov@gmail.com",
    url: "https://github.com/lahmatiy",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/css-tree",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/css-tree/LICENSE",
  },
  "css.escape@1.5.1": {
    licenses: "MIT",
    repository: "https://github.com/mathiasbynens/CSS.escape",
    publisher: "Mathias Bynens",
    url: "https://mathiasbynens.be/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/css.escape",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/css.escape/LICENSE-MIT.txt",
  },
  "csstype@3.1.1": {
    licenses: "MIT",
    repository: "https://github.com/frenic/csstype",
    publisher: "Fredrik Nicol",
    email: "fredrik.nicol@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/csstype",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/csstype/LICENSE",
  },
  "deep-equal@2.2.0": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/node-deep-equal",
    publisher: "James Halliday",
    email: "mail@substack.net",
    url: "http://substack.net",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/deep-equal",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/deep-equal/LICENSE",
  },
  "define-properties@1.1.4": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/define-properties",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/define-properties",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/define-properties/LICENSE",
  },
  "delayed-stream@1.0.0": {
    licenses: "MIT",
    repository: "https://github.com/felixge/node-delayed-stream",
    publisher: "Felix Geisendörfer",
    email: "felix@debuggable.com",
    url: "http://debuggable.com/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/delayed-stream",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/delayed-stream/License",
  },
  "dequal@2.0.3": {
    licenses: "MIT",
    repository: "https://github.com/lukeed/dequal",
    publisher: "Luke Edwards",
    email: "luke.edwards05@gmail.com",
    url: "https://lukeed.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dequal",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dequal/license",
  },
  "diff-sequences@27.5.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/jest",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/diff-sequences",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/diff-sequences/LICENSE",
  },
  "dom-accessibility-api@0.5.16": {
    licenses: "MIT",
    repository: "https://github.com/eps1lon/dom-accessibility-api",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dom-accessibility-api",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dom-accessibility-api/LICENSE.md",
  },
  "dom-helpers@5.2.1": {
    licenses: "MIT",
    repository: "https://github.com/react-bootstrap/dom-helpers",
    publisher: "Jason Quense",
    email: "monastic.panic@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dom-helpers",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/dom-helpers/LICENSE",
  },
  "error-stack-parser@2.1.4": {
    licenses: "MIT",
    repository: "https://github.com/stacktracejs/error-stack-parser",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/error-stack-parser",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/error-stack-parser/LICENSE",
  },
  "es-get-iterator@1.1.3": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/es-get-iterator",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/es-get-iterator",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/es-get-iterator/LICENSE",
  },
  "escape-string-regexp@1.0.5": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/escape-string-regexp",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/escape-string-regexp",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/escape-string-regexp/license",
  },
  "fast-deep-equal@3.1.3": {
    licenses: "MIT",
    repository: "https://github.com/epoberezkin/fast-deep-equal",
    publisher: "Evgeny Poberezkin",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-deep-equal",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-deep-equal/LICENSE",
  },
  "fast-loops@1.1.3": {
    licenses: "MIT",
    repository: "https://github.com/robinweser/fast-loops",
    publisher: "robinweser",
    email: "contact@weser.io",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-loops",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-loops/LICENSE",
  },
  "fast-shallow-equal@1.0.0": {
    licenses: "Public Domain",
    repository: "https://github.com/streamich/fast-shallow-equal",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-shallow-equal",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fast-shallow-equal/LICENSE",
  },
  "fastest-stable-stringify@2.0.2": {
    licenses: "MIT",
    repository: "https://github.com/streamich/fastest-stable-stringify",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fastest-stable-stringify",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/fastest-stable-stringify/LICENSE",
  },
  "follow-redirects@1.15.2": {
    licenses: "MIT",
    repository: "https://github.com/follow-redirects/follow-redirects",
    publisher: "Ruben Verborgh",
    email: "ruben@verborgh.org",
    url: "https://ruben.verborgh.org/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/follow-redirects",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/follow-redirects/LICENSE",
  },
  "for-each@0.3.3": {
    licenses: "MIT",
    repository: "https://github.com/Raynos/for-each",
    publisher: "Raynos",
    email: "raynos2@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/for-each",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/for-each/LICENSE",
  },
  "form-data@4.0.0": {
    licenses: "MIT",
    repository: "https://github.com/form-data/form-data",
    publisher: "Felix Geisendörfer",
    email: "felix@debuggable.com",
    url: "http://debuggable.com/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/axios/node_modules/form-data",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/axios/node_modules/form-data/License",
  },
  "function-bind@1.1.1": {
    licenses: "MIT",
    repository: "https://github.com/Raynos/function-bind",
    publisher: "Raynos",
    email: "raynos2@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/function-bind",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/function-bind/LICENSE",
  },
  "functions-have-names@1.2.3": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/functions-have-names",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/functions-have-names",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/functions-have-names/LICENSE",
  },
  "get-intrinsic@1.2.0": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/get-intrinsic",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/get-intrinsic",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/get-intrinsic/LICENSE",
  },
  "gopd@1.0.1": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/gopd",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/gopd",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/gopd/LICENSE",
  },
  "has-bigints@1.0.2": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/has-bigints",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-bigints",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-bigints/LICENSE",
  },
  "has-flag@3.0.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/has-flag",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-flag",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-flag/license",
  },
  "has-flag@4.0.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/has-flag",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/has-flag",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/has-flag/license",
  },
  "has-property-descriptors@1.0.0": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/has-property-descriptors",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-property-descriptors",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-property-descriptors/LICENSE",
  },
  "has-symbols@1.0.3": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/has-symbols",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-symbols",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-symbols/LICENSE",
  },
  "has-tostringtag@1.0.0": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/has-tostringtag",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-tostringtag",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has-tostringtag/LICENSE",
  },
  "has@1.0.3": {
    licenses: "MIT",
    repository: "https://github.com/tarruda/has",
    publisher: "Thiago de Arruda",
    email: "tpadilha84@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/has/LICENSE-MIT",
  },
  "hyphenate-style-name@1.0.4": {
    licenses: "BSD-3-Clause",
    repository: "https://github.com/rexxars/hyphenate-style-name",
    publisher: "Espen Hovlandsdal",
    email: "espen@hovlandsdal.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/hyphenate-style-name",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/hyphenate-style-name/LICENSE",
  },
  "indent-string@4.0.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/indent-string",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/indent-string",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/indent-string/license",
  },
  "inline-style-prefixer@6.0.4": {
    licenses: "MIT",
    repository: "https://github.com/robinweser/inline-style-prefixer",
    publisher: "Robin Weser",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/inline-style-prefixer",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/inline-style-prefixer/LICENSE",
  },
  "internal-slot@1.0.4": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/internal-slot",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/internal-slot",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/internal-slot/LICENSE",
  },
  "invariant@2.2.4": {
    licenses: "MIT",
    repository: "https://github.com/zertosh/invariant",
    publisher: "Andres Suarez",
    email: "zertosh@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/invariant",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/invariant/LICENSE",
  },
  "ionicons@7.0.0": {
    licenses: "MIT",
    repository: "https://github.com/ionic-team/ionicons",
    publisher: "ionic-team",
  },
  "is-arguments@1.1.1": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-arguments",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-arguments",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-arguments/LICENSE",
  },
  "is-array-buffer@3.0.1": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-array-buffer",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-array-buffer",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-array-buffer/LICENSE",
  },
  "is-bigint@1.0.4": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-bigint",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-bigint",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-bigint/LICENSE",
  },
  "is-boolean-object@1.1.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-boolean-object",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-boolean-object",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-boolean-object/LICENSE",
  },
  "is-callable@1.2.7": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-callable",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-callable",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-callable/LICENSE",
  },
  "is-date-object@1.0.5": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-date-object",
    publisher: "Jordan Harband",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-date-object",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-date-object/LICENSE",
  },
  "is-map@2.0.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-map",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-map",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-map/LICENSE",
  },
  "is-number-object@1.0.7": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-number-object",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-number-object",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-number-object/LICENSE",
  },
  "is-regex@1.1.4": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-regex",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-regex",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-regex/LICENSE",
  },
  "is-set@2.0.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-set",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-set",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-set/LICENSE",
  },
  "is-shared-array-buffer@1.0.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-shared-array-buffer",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-shared-array-buffer",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-shared-array-buffer/LICENSE",
  },
  "is-string@1.0.7": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/is-string",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-string",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-string/LICENSE",
  },
  "is-symbol@1.0.4": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-symbol",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-symbol",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-symbol/LICENSE",
  },
  "is-typed-array@1.1.10": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-typed-array",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-typed-array",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-typed-array/LICENSE",
  },
  "is-weakmap@2.0.1": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-weakmap",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-weakmap",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-weakmap/LICENSE",
  },
  "is-weakset@2.0.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/is-weakset",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-weakset",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/is-weakset/LICENSE",
  },
  "isarray@2.0.5": {
    licenses: "MIT",
    repository: "https://github.com/juliangruber/isarray",
    publisher: "Julian Gruber",
    email: "mail@juliangruber.com",
    url: "http://juliangruber.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/isarray",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/isarray/LICENSE",
  },
  "jest-diff@27.5.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/jest",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-diff",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-diff/LICENSE",
  },
  "jest-get-type@27.5.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/jest",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-get-type",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-get-type/LICENSE",
  },
  "jest-matcher-utils@27.5.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/jest",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/LICENSE",
  },
  "js-cookie@2.2.1": {
    licenses: "MIT",
    repository: "https://github.com/js-cookie/js-cookie",
    publisher: "Klaus Hartl",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-use/node_modules/js-cookie",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-use/node_modules/js-cookie/LICENSE",
  },
  "js-cookie@3.0.1": {
    licenses: "MIT",
    repository: "https://github.com/js-cookie/js-cookie",
    publisher: "Klaus Hartl",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/js-cookie",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/js-cookie/LICENSE",
  },
  "js-tokens@4.0.0": {
    licenses: "MIT",
    repository: "https://github.com/lydell/js-tokens",
    publisher: "Simon Lydell",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/js-tokens",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/js-tokens/LICENSE",
  },
  "leaflet@1.9.3": {
    licenses: "BSD-2-Clause",
    repository: "https://github.com/Leaflet/Leaflet",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/leaflet",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/leaflet/LICENSE",
  },
  "lodash.debounce@4.0.8": {
    licenses: "MIT",
    repository: "https://github.com/lodash/lodash",
    publisher: "John-David Dalton",
    email: "john.david.dalton@gmail.com",
    url: "http://allyoucanleet.com/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lodash.debounce",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lodash.debounce/LICENSE",
  },
  "lodash@4.17.21": {
    licenses: "MIT",
    repository: "https://github.com/lodash/lodash",
    publisher: "John-David Dalton",
    email: "john.david.dalton@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lodash",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lodash/LICENSE",
  },
  "loose-envify@1.4.0": {
    licenses: "MIT",
    repository: "https://github.com/zertosh/loose-envify",
    publisher: "Andres Suarez",
    email: "zertosh@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/loose-envify",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/loose-envify/LICENSE",
  },
  "lz-string@1.4.4": {
    licenses: "WTFPL",
    repository: "https://github.com/pieroxy/lz-string",
    publisher: "pieroxy",
    email: "pieroxy@pieroxy.net",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lz-string",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/lz-string/LICENSE.txt",
  },
  "material-design-icons@4.0.0": {
    licenses: "Apache-2.0",
    repository: "https://github.com/google/material-design-icons",
    publisher: "Google",
  },
  "mdn-data@2.0.14": {
    licenses: "CC0-1.0",
    repository: "https://github.com/mdn/data",
    publisher: "Mozilla Developer Network",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/mdn-data",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/mdn-data/LICENSE",
  },
  "mime-db@1.52.0": {
    licenses: "MIT",
    repository: "https://github.com/jshttp/mime-db",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/mime-db",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/mime-db/LICENSE",
  },
  "mime-types@2.1.35": {
    licenses: "MIT",
    repository: "https://github.com/jshttp/mime-types",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/mime-types",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/mime-types/LICENSE",
  },
  "min-indent@1.0.1": {
    licenses: "MIT",
    repository: "https://github.com/thejameskyle/min-indent",
    publisher: "James Kyle",
    email: "me@thejameskyle.com",
    url: "thejameskyle.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/min-indent",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/min-indent/license",
  },
  "nano-css@5.3.5": {
    licenses: "Unlicense",
    repository: "https://github.com/streamich/nano-css",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/LICENSE",
  },
  "object-assign@4.1.1": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/object-assign",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-assign",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-assign/license",
  },
  "object-inspect@1.12.3": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/object-inspect",
    publisher: "James Halliday",
    email: "mail@substack.net",
    url: "http://substack.net",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-inspect",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-inspect/LICENSE",
  },
  "object-is@1.1.5": {
    licenses: "MIT",
    repository: "https://github.com/es-shims/object-is",
    publisher: "Jordan Harband",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-is",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-is/LICENSE",
  },
  "object-keys@1.1.1": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/object-keys",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-keys",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object-keys/LICENSE",
  },
  "object.assign@4.1.4": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/object.assign",
    publisher: "Jordan Harband",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object.assign",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/object.assign/LICENSE",
  },
  "pretty-format@27.5.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/jest",
    publisher: "James Kyle",
    email: "me@thejameskyle.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/pretty-format",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/pretty-format/LICENSE",
  },
  "prop-types-extra@1.1.1": {
    licenses: "MIT",
    repository: "https://github.com/react-bootstrap/prop-types-extra",
    publisher: "Matthew L Smith",
    email: "mtscout6@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types-extra",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types-extra/LICENSE",
  },
  "prop-types@15.8.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/prop-types",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types/LICENSE",
  },
  "proxy-from-env@1.1.0": {
    licenses: "MIT",
    repository: "https://github.com/Rob--W/proxy-from-env",
    publisher: "Rob Wu",
    email: "rob@robwu.nl",
    url: "https://robwu.nl/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/proxy-from-env",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/proxy-from-env/LICENSE",
  },
  "react-bootstrap-typeahead@6.0.0": {
    licenses: "MIT",
    repository: "https://github.com/ericgio/react-bootstrap-typeahead",
    publisher: "ericgio",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-bootstrap-typeahead",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-bootstrap-typeahead/LICENSE.md",
  },
  "react-bootstrap@2.7.0": {
    licenses: "MIT",
    repository: "https://github.com/react-bootstrap/react-bootstrap",
    publisher: "Stephen J. Collings",
    email: "stevoland@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-bootstrap",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-bootstrap/LICENSE",
  },
  "react-dom@18.2.0": {
    licenses: "MIT",
    repository: "https://github.com/facebook/react",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-dom/LICENSE",
  },
  "react-fast-compare@3.2.0": {
    licenses: "MIT",
    repository: "https://github.com/FormidableLabs/react-fast-compare",
    publisher: "Chris Bolin",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-fast-compare",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-fast-compare/LICENSE",
  },
  "react-icons@4.7.1": {
    licenses: "MIT",
    repository: "https://github.com/react-icons/react-icons",
    publisher: "Goran Gajic",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-icons",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-icons/LICENSE",
  },
  "react-is@16.13.1": {
    licenses: "MIT",
    repository: "https://github.com/facebook/react",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types/node_modules/react-is",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/prop-types/node_modules/react-is/LICENSE",
  },
  "react-is@17.0.2": {
    licenses: "MIT",
    repository: "https://github.com/facebook/react",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-is",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-is/LICENSE",
  },
  "react-leaflet@4.2.0": {
    licenses: "Hippocratic-2.1",
    repository: "https://github.com/PaulLeCam/react-leaflet",
    publisher: "Paul Le Cam",
    email: "paul@ulem.net",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-leaflet",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-leaflet/LICENSE.md",
  },
  "react-lifecycles-compat@3.0.4": {
    licenses: "MIT",
    repository: "https://github.com/reactjs/react-lifecycles-compat",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-lifecycles-compat",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-lifecycles-compat/LICENSE.md",
  },
  "react-overlays@5.2.1": {
    licenses: "MIT",
    repository: "https://github.com/react-bootstrap/react-overlays",
    publisher: "Jason Quense",
    email: "monastic.panic@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-overlays",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-overlays/LICENSE",
  },
  "react-popper@2.3.0": {
    licenses: "MIT",
    repository: "https://github.com/popperjs/react-popper",
    publisher: "Travis Arnold",
    email: "travis@souporserious.com",
    url: "http://souporserious.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-popper",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-popper/LICENSE",
  },
  "react-router-dom@6.8.0": {
    licenses: "MIT",
    repository: "https://github.com/remix-run/react-router",
    publisher: "Remix Software",
    email: "hello@remix.run",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-router-dom",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-router-dom/LICENSE.md",
  },
  "react-router@6.8.0": {
    licenses: "MIT",
    repository: "https://github.com/remix-run/react-router",
    publisher: "Remix Software",
    email: "hello@remix.run",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-router",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-router/LICENSE.md",
  },
  "react-transition-group@4.4.5": {
    licenses: "BSD-3-Clause",
    repository: "https://github.com/reactjs/react-transition-group",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-transition-group",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-transition-group/LICENSE",
  },
  "react-universal-interface@0.6.2": {
    licenses: "Public Domain",
    repository: "https://github.com/streamich/react-universal-interface",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-universal-interface",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-universal-interface/LICENSE",
  },
  "react-use@17.4.0": {
    licenses: "Unlicense",
    repository: "https://github.com/streamich/react-use",
    publisher: "@streamich",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-use",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react-use/LICENSE",
  },
  "react@18.2.0": {
    licenses: "MIT",
    repository: "https://github.com/facebook/react",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/react/LICENSE",
  },
  "redent@3.0.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/redent",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/redent",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/redent/license",
  },
  "regenerator-runtime@0.13.11": {
    licenses: "MIT",
    repository:
      "https://github.com/facebook/regenerator/tree/main/packages/runtime",
    publisher: "Ben Newman",
    email: "bn@cs.stanford.edu",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/regenerator-runtime",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/regenerator-runtime/LICENSE",
  },
  "regexp.prototype.flags@1.4.3": {
    licenses: "MIT",
    repository: "https://github.com/es-shims/RegExp.prototype.flags",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/regexp.prototype.flags",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/regexp.prototype.flags/LICENSE",
  },
  "resize-observer-polyfill@1.5.1": {
    licenses: "MIT",
    repository: "https://github.com/que-etc/resize-observer-polyfill",
    publisher: "Denis Rul",
    email: "que.etc@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/resize-observer-polyfill",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/resize-observer-polyfill/LICENSE",
  },
  "rtl-css-js@1.16.1": {
    licenses: "MIT",
    repository: "https://github.com/kentcdodds/rtl-css-js",
    publisher: "Kent C. Dodds",
    email: "kent@doddsfamily.us",
    url: "http://kentcdodds.com/",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/rtl-css-js",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/rtl-css-js/LICENSE",
  },
  "scheduler@0.23.0": {
    licenses: "MIT",
    repository: "https://github.com/facebook/react",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/scheduler",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/scheduler/LICENSE",
  },
  "screenfull@5.2.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/screenfull.js",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/screenfull",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/screenfull/license",
  },
  "scroll-into-view-if-needed@2.2.31": {
    licenses: "MIT",
    repository:
      "https://github.com/scroll-into-view/scroll-into-view-if-needed",
    publisher: "Cody Olsen",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/scroll-into-view-if-needed",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/scroll-into-view-if-needed/LICENSE",
  },
  "set-harmonic-interval@1.0.1": {
    licenses: "Unlicense",
    repository: "https://github.com/streamich/set-harmonic-interval",
    publisher: "streamich",
    url: "https://github.com/streamich",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/set-harmonic-interval",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/set-harmonic-interval/LICENSE",
  },
  "side-channel@1.0.4": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/side-channel",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/side-channel",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/side-channel/LICENSE",
  },
  "source-map@0.5.6": {
    licenses: "BSD-3-Clause",
    repository: "https://github.com/mozilla/source-map",
    publisher: "Nick Fitzgerald",
    email: "nfitzgerald@mozilla.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-gps/node_modules/source-map",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-gps/node_modules/source-map/LICENSE",
  },
  "source-map@0.6.1": {
    licenses: "BSD-3-Clause",
    repository: "https://github.com/mozilla/source-map",
    publisher: "Nick Fitzgerald",
    email: "nfitzgerald@mozilla.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/source-map",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/nano-css/node_modules/source-map/LICENSE",
  },
  "sourcemap-codec@1.4.8": {
    licenses: "MIT",
    repository: "https://github.com/Rich-Harris/sourcemap-codec",
    publisher: "Rich Harris",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/sourcemap-codec",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/sourcemap-codec/LICENSE",
  },
  "stack-generator@2.0.10": {
    licenses: "MIT",
    repository: "https://github.com/stacktracejs/stack-generator",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stack-generator",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stack-generator/LICENSE",
  },
  "stackframe@1.3.4": {
    licenses: "MIT",
    repository: "https://github.com/stacktracejs/stackframe",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stackframe",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stackframe/LICENSE",
  },
  "stacktrace-gps@3.1.2": {
    licenses: "MIT",
    repository: "https://github.com/stacktracejs/stacktrace-gps",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-gps",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-gps/LICENSE",
  },
  "stacktrace-js@2.0.2": {
    licenses: "MIT",
    repository: "https://github.com/stacktracejs/stacktrace.js",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-js",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stacktrace-js/LICENSE",
  },
  "stop-iteration-iterator@1.0.0": {
    licenses: "MIT",
    repository: "https://github.com/ljharb/stop-iteration-iterator",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stop-iteration-iterator",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stop-iteration-iterator/LICENSE",
  },
  "strip-indent@3.0.0": {
    licenses: "MIT",
    repository: "https://github.com/sindresorhus/strip-indent",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/strip-indent",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/strip-indent/license",
  },
  "stylis@4.1.3": {
    licenses: "MIT",
    repository: "https://github.com/thysultan/stylis.js",
    publisher: "Sultan Tarimo",
    email: "sultantarimo@me.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stylis",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/stylis/LICENSE",
  },
  "supports-color@5.5.0": {
    licenses: "MIT",
    repository: "https://github.com/chalk/supports-color",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/supports-color",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/supports-color/license",
  },
  "supports-color@7.2.0": {
    licenses: "MIT",
    repository: "https://github.com/chalk/supports-color",
    publisher: "Sindre Sorhus",
    email: "sindresorhus@gmail.com",
    url: "sindresorhus.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/supports-color",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/jest-matcher-utils/node_modules/supports-color/license",
  },
  "throttle-debounce@3.0.1": {
    licenses: "MIT",
    repository: "https://github.com/niksy/throttle-debounce",
    publisher: "Ivan Nikolić",
    email: "niksy5@gmail.com",
    url: "http://ivannikolic.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/throttle-debounce",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/throttle-debounce/LICENSE.md",
  },
  "toggle-selection@1.0.6": {
    licenses: "MIT",
    repository: "https://github.com/sudodoki/toggle-selection",
    publisher: "sudodoki",
    email: "smd.deluzion@gmail.com",
    url: "sudodoki.name",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/toggle-selection",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/toggle-selection/README.md",
  },
  "ts-easing@0.2.0": {
    licenses: "Unlicense",
    repository: "https://github.com/streamich/ts-easing",
    publisher: "@streamich",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ts-easing",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/ts-easing/LICENSE",
  },
  "tslib@2.5.0": {
    licenses: "0BSD",
    repository: "https://github.com/Microsoft/tslib",
    publisher: "Microsoft Corp.",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/tslib",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/tslib/LICENSE.txt",
  },
  "typescript@4.9.5": {
    licenses: "Apache-2.0",
    repository: "https://github.com/Microsoft/TypeScript",
    publisher: "Microsoft Corp.",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/typescript",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/typescript/LICENSE.txt",
  },
  "uncontrollable@7.2.1": {
    licenses: "MIT",
    repository: "https://github.com/jquense/uncontrollable",
    publisher: "Jason Quense",
    email: "monastic.panic@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/uncontrollable",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/uncontrollable/LICENSE",
  },
  "warning@4.0.3": {
    licenses: "MIT",
    repository: "https://github.com/BerkeleyTrue/warning",
    publisher: "Berkeley Martinez",
    email: "berkeley@berkeleytrue.com",
    url: "http://www.berkeleytrue.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/warning",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/warning/LICENSE.md",
  },
  "web-vitals@2.1.4": {
    licenses: "Apache-2.0",
    repository: "https://github.com/GoogleChrome/web-vitals",
    publisher: "Philip Walton",
    email: "philip@philipwalton.com",
    url: "http://philipwalton.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/web-vitals",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/web-vitals/LICENSE",
  },
  "which-boxed-primitive@1.0.2": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/which-boxed-primitive",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-boxed-primitive",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-boxed-primitive/LICENSE",
  },
  "which-collection@1.0.1": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/which-collection",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-collection",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-collection/LICENSE",
  },
  "which-typed-array@1.1.9": {
    licenses: "MIT",
    repository: "https://github.com/inspect-js/which-typed-array",
    publisher: "Jordan Harband",
    email: "ljharb@gmail.com",
    url: "http://ljharb.codes",
    path: "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-typed-array",
    licenseFile:
      "/home/timo/data/dropbox/Dropbox/src/nafigaattori/frontend/node_modules/which-typed-array/LICENSE",
  },
};

export default ExternalDependencies;
