import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { SiteProps } from "../common/Types";
import { TILEURL } from '../App';


function SiteMap({site}:{site: SiteProps}): JSX.Element {
	return (
		<div className="sitemap-container">
			<MapContainer
				center={[site.address.lat, site.address.lon]}
				zoom={6}
				doubleClickZoom={false}
				scrollWheelZoom={false}
				dragging={false}
				zoomControl={false}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMapin</a> tekijät'
					url={TILEURL + "/{z}/{x}/{y}.png"}
				/>
				<Marker position={[site.address.lat, site.address.lon]} />
			</MapContainer>
		</div>
	)
}

export default SiteMap;
