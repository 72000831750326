import React from "react";
import { useCallback } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ToggleButton from 'react-bootstrap/ToggleButton';
import { MdTimeline, MdAltRoute } from "react-icons/md";
import SiteIcons from "./SiteIcons";
import { getTravelledDistance, getApproximateDistanceFromRoute, getPlaceholderImage } from "../common/Utils";
import { RouteSiteProps } from "../common/Types";

const STATUSES = [
	{ name: "Lisää reitille", value: 'accepted', variantSelected: 'success', variantNotSelected: 'light' },
	{ name: "Ohita kohde", value: 'rejected', variantSelected: 'danger', variantNotSelected: 'light' },
];

function SiteCard(
	{routeSite, highlightedUUID, siteIndex, numVisibleSites, setShowDetailedSite, setSiteStatus, showButtons}
	:{routeSite: RouteSiteProps, highlightedUUID: string | null, siteIndex: number, numVisibleSites: number, setShowDetailedSite: (rs: RouteSiteProps) => void, setSiteStatus: (uuid: string, status: string) => void, showButtons: boolean }
): JSX.Element {

	const address = routeSite.site.address;

	const getStatusButtons = useCallback((routeSite: RouteSiteProps, setSiteStatus: (uuid: string, status: string) => void) => {
		return (
			<ButtonGroup className="site-status-buttons">
				{STATUSES.map((status: any, idx: number): JSX.Element => {
						return (
							<ToggleButton
								key={idx}
								id={`status-${idx}`}
								type="radio"
								variant={routeSite.status === status.value ? status.variantSelected : status.variantNotSelected}
								value={status.value}
								size="lg"
								checked={routeSite.status === status.value}
								onClick={e => {setSiteStatus(routeSite.site.uuid, status.value); e.preventDefault();}}
								style={{ border: "2px solid lightgray" }}
							>
								{status.name}
							</ToggleButton>
						);
					}
				)}
			</ButtonGroup>
		);
	}, []);


	return (
		<Card 
				className="hover-pointer-element p-0"
				bg={function() {
					if (routeSite.status === 'accepted') return 'success';
					// if (routeSite.status === 'rejected') return 'danger';
					return "secondary"
				}()}
				border={routeSite.site.uuid === highlightedUUID && routeSite.status !== "rejected" ? "primary" : "light"}
				text="light"
				onClick={e => !e.isDefaultPrevented() && setShowDetailedSite(routeSite)}
		>
			<Card.Body>
				<Card.Header>
					<table className="route-metrics text-center">
						<tbody>
							<tr>
								<td className="site-pos">{siteIndex + 1} / {numVisibleSites}</td>
								<td className="route-travelled"><MdTimeline /> {getTravelledDistance(routeSite.travelled_distance)} km</td>
								<td className="distance-from-route"><MdAltRoute /> {getApproximateDistanceFromRoute(routeSite.distance_from_route)} km </td>
							</tr>
						</tbody>
					</table>
				</Card.Header>
				<Container>
					<Row>
						<Col xs={12}>
							<h5>
								{routeSite.site.name}
							</h5>
							<h6>
								{address.village && address.municipality ? `${address.village} (${address.municipality})` : address.village || address.municipality}<br/>
							</h6>
						</Col>
						<Col className="text-center d-flex align-items-center" xs={12}>
							<span style={{ margin: "0 auto" }}>
								{routeSite.site.thumbnail
									? <><img className="site-thumbnail" src={routeSite.site.thumbnail} /><br/></>
									: <><span className="site-noimage">{getPlaceholderImage(routeSite.site)}</span><br/></>
								}
							</span>
						</Col>
					</Row>
					<Row>
						<Col>
							<SiteIcons site={routeSite.site} size="compact" />
						</Col>
					</Row>
				</Container>
			</Card.Body>
			{showButtons &&
				<Card.Footer className="text-center">
					{getStatusButtons(routeSite, setSiteStatus)}
				</Card.Footer>
			}
		</Card>
	);
}

export default SiteCard;
