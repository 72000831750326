import React from "react";
import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from "react-bootstrap/Modal";
import OverLayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { IoAdd, IoArrowUp, IoArrowDown, IoCheckmark, IoHome, IoSave, IoSettings, IoTrash, IoPerson } from "react-icons/io5";
import { MdLocationCity } from "react-icons/md";
import useGlobalContext from '../common/GlobalContext';
import { RouteProps, Waypoint, EmptyWaypoint } from "../common/Types";
import Municipalities from "../common/Municipalities";
import YesNo from "./YesNo";
import RouteSettings from "./RouteSettings";
import RouteSave from "./RouteSave";

const variantAvailable = 'light'
const variantUnavailable = 'secondary';
const WAYPOINT_OPTIONS = Municipalities.map((m: string) => {return { value: m, label: m }});
const LOCALSTORAGE_NAFIGAATTORI_SETTINGS_INFO_SEEN = 'nafigaattori_settings_info_seen';

function WaypointEntry(
	{ waypoint, updateWaypoint, disabled, onDelete, isHome, route, setShowModalFor, numWaypoints, onMove, firstBlankOrdinal, readOnly, isReady }:
		{ waypoint: Waypoint, updateWaypoint: (waypoint: Waypoint) => void, disabled: boolean, onDelete?: () => void, isHome: boolean, route: RouteProps | null, setShowModalFor: (name: string) => void, numWaypoints: number, onMove: (ordinal: number, change: number) => void, firstBlankOrdinal: number, readOnly: boolean, isReady: boolean }
) {
	const [municipality, setMunicipality] = useState(waypoint.municipality || '');
	const [shouldShowInfo, setShouldShowInfo] = useState<boolean>(localStorage.getItem(LOCALSTORAGE_NAFIGAATTORI_SETTINGS_INFO_SEEN) !== 'true');

	useEffect(() => {
		if (waypoint.municipality !== municipality)
			updateWaypoint({ ...waypoint, municipality });
	}, [municipality]);

	return (
		<Row className="mb-1">
			<Form.Group className="col col-sm-12 mb-3" controlId={`WaypointForm${waypoint.ordinal}`}>
				<Form.Label column="lg" style={{ width: "100%" }}>
					{
						waypoint.ordinal === 1
							? "Lähtöpaikka"
							: `${waypoint.ordinal - 1}. määränpää`
					}
					{!readOnly && numWaypoints > 2 && waypoint.ordinal > 1 &&
						<>
							<Button onClick={() => onMove(waypoint.ordinal, -1)} className="ms-2" size="sm" disabled={disabled || waypoint.ordinal === 2}><IoArrowUp /></Button>
							<Button onClick={() => onMove(waypoint.ordinal, 1)} className="ms-2" size="sm" disabled={disabled || waypoint.ordinal === numWaypoints}><IoArrowDown /></Button>
						</>
					}
					{!readOnly && onDelete !== undefined &&
							<Button disabled={disabled} className="ms-1 mt-0" style={{ float: 'right' }} size="sm" variant="danger" onClick={onDelete}>
								<IoTrash />
							</Button>
					}
					{!readOnly && waypoint.ordinal === 1 &&
						<div className="settings-buttons">
							<OverLayTrigger 
									show={route && route.uuid && shouldShowInfo ? true : false}
									overlay={
										<Popover id="popover-basic" style={{ border: "10px solid green"}}>
											<Popover.Header as="h3" style={{backgroundColor: "white"}}><span style={{ color: "green", fontWeight: "bold" }}>Reitti löydetty</span></Popover.Header>
											<Popover.Body style={{ backgroundColor: "white" }} >
													Täältä voit muuttaa reitin asetuksia, esimerkiksi rajoittaa ehdotettavien kohteiden etäisyyksiä reitiltä. 
													<br/>
													<br/>
													<Button variant="success" onClick={() => {setShouldShowInfo(false); localStorage.setItem(LOCALSTORAGE_NAFIGAATTORI_SETTINGS_INFO_SEEN, 'true')}}>
														OK
													</Button>
											</Popover.Body>
										</Popover>
									}
									placement="bottom"
							>
								<Button
									className="me-1"
									size="sm"
									variant="warning"
									disabled={!route || !route.uuid || route.uuid === 'uusi'}
									onClick={() => setShowModalFor('settings')}
								>
									<IoSettings/> Asetukset
								</Button>
							</OverLayTrigger>
							<Button
								className="ms-1"
								size="sm"
								disabled={!route || !route.uuid || route.uuid === 'uusi'}
								variant={function(): string {
									if (!route || !route.uuid)
										return variantUnavailable;
									if (!route.isSaved)
										return 'danger';
									return variantAvailable;
								}()}
								onClick={() => setShowModalFor('save')}
							>
								<IoSave/> Tallennus
							</Button>
						</div>
					}
				</Form.Label>
					<InputGroup className="waypoint-field">
						<div className="waypoint-icon">
							<InputGroup.Text style={{ height: "38px", borderRadius: "0px" }}>{isHome ? <IoHome /> : <MdLocationCity/>}</InputGroup.Text>
						</div>
						<div className="waypoint-select">
							{isReady && 1 > 0
								? <div className="waypoint-ready-entry">
										<span className="waypoint-ready-entry-content">{municipality}</span>
									</div>
								: <Select
										className="basic-single"
										classNamePrefix="select"
										options={WAYPOINT_OPTIONS} 
										isMulti={false}
										isDisabled={disabled}
										isClearable={true}
										isSearchable={true}
										filterOption={(option, inputValue) => { return (option.value || '').toLowerCase().startsWith((inputValue || '').toLowerCase()) }}
										onChange={(v) => {setMunicipality(v && v.value ? v.value : '')}}
										noOptionsMessage={() => <span>Ei hakutuloksia</span>}
										placeholder={`Valitse ${waypoint.ordinal === 1 ? "lähtöpaikka" : "määränpää"}`}
										value={{label: municipality, value: municipality}}
										blurInputOnSelect={false}
										autoFocus={waypoint.ordinal === firstBlankOrdinal && !municipality ? true : false}
									/>
							}
						</div>
					</InputGroup>
			</Form.Group>
		</Row>
	);
}

function Waypoints({ onCompute, loading, error, maxed, sitesSelected, readOnly }:{ onCompute: (e: React.BaseSyntheticEvent) => void, loading: boolean, error: string | null, maxed: boolean, sitesSelected: boolean, readOnly: boolean }): JSX.Element {
	const { route, setRoute, setRouteSites, waypoints, setWaypoints } = useGlobalContext();
	const [ waypointsDirty, setWaypointsDirty ] = useState<boolean>(route ? false : true);
	const [ showModalFor, setShowModalFor ] = useState<string | null>(null);
	const [ locked, setLocked ] = useState<boolean>(false);

	const currentWaypoints = waypoints || [{ ordinal: 1, municipality: ""}, { ordinal: 2, municipality: ""}];
	const firstBlankOrdinal = (currentWaypoints.find((wp) => !wp.municipality) || {ordinal: -1}).ordinal;

	const goto = useNavigate();

	if (!setWaypoints)
		return <h3>There was an error, please reload.</h3>;

	const updateWaypoint = useCallback((updatedWaypoint: Waypoint) => {
		const newWaypoints = currentWaypoints.map((otherWaypoint: Waypoint) => {
			if (updatedWaypoint.ordinal === otherWaypoint.ordinal)
				return updatedWaypoint;
			return otherWaypoint;
		});
		setWaypoints(newWaypoints);
		setWaypointsDirty(true);
	}, [currentWaypoints, waypoints]);

	const addWaypoint = useCallback((): void => {
		setWaypoints(currentWaypoints.concat([{ ...EmptyWaypoint, ordinal: currentWaypoints.length + 1}]))
		setWaypointsDirty(true);
	}, [currentWaypoints, waypoints]);

	const delWaypoint = useCallback((ordinal: number): void => {
		const newWaypoints = currentWaypoints.filter((wp: Waypoint) => wp.ordinal !== ordinal);
		newWaypoints.forEach((wp: Waypoint, index: number) => wp.ordinal = index + 1);
		setWaypoints(newWaypoints);
		setWaypointsDirty(true);
	}, [currentWaypoints, waypoints]);

	const moveWaypoint = useCallback((ordinal: number, change: number): void => {
		if (currentWaypoints) {
			const self = currentWaypoints[ordinal - 1];
			const other = currentWaypoints[ordinal - 1 + change];
			self.ordinal = other.ordinal;
			other.ordinal = ordinal;
			const newWaypoints = currentWaypoints.map((wp) => wp);
			newWaypoints[ordinal - 1] = other;
			newWaypoints[ordinal - 1 + change] = self;
			setWaypoints(newWaypoints);
			setWaypointsDirty(true);
		}
	}, [currentWaypoints, waypoints]);

	const isPublic = route && route.public ? true : false;
	const isReady = route && route.ready ? true : false;
	const newRouteNeeded = readOnly || sitesSelected || isReady || maxed;

	return (
		<>
			{showModalFor === 'settings' && <RouteSettings onClose={() => setShowModalFor(null)}/>}
			{showModalFor === 'save' && <RouteSave onClose={() => setShowModalFor(null)}/>}
			<Modal show={locked}>
				<Modal.Header>
					<h1>Luo uusi reitti?</h1>
				</Modal.Header>
				<Modal.Body>
					{readOnly 
						? <p>Haluatko lopettaa tämän reitin tarkastelun ja luoda kokonaan uuden reitin?</p>
						: <p>Olet jo valinnut kohteita nykyiselle reitille. Reittiä ei voi muuttaa kadottamatta nykyisiä valintoja.</p>
					}
					{!readOnly && (maxed
						? <p>Et tällä hetkellä voi luoda uutta reittiä, sillä tallentamiesi reittien määrä on jo täynnä. Mikäli haluat tyhjentää valinnat ja luoda kokonaan uuden reitin, käy ensin poistamassa yksi omista reiteistäsi valitsemalla ylävalikosta <IoPerson /></p>
						: <p>Voit halutessasi luoda kokonaan uuden reitin. <br/><span style={{ color: 'red' }}>Huom!</span> Tallentamattomat muutokset menetetään.</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<YesNo yesText="Luo uusi reitti" onYes={maxed ? null : () => { setLocked(false); if (setRouteSites) setRouteSites(null); if (setRoute) setRoute(null); goto('/reitti/uusi') }} noText="Peruuta" onNo={() => setLocked(false)} />
				</Modal.Footer>
			</Modal>

			<Form onSubmit={(e: React.BaseSyntheticEvent) => {
					e.preventDefault();
					if (newRouteNeeded) {
						setLocked(true);
					}
					else {
						setWaypointsDirty(false);
						onCompute(e);
					}
			}}>
				{currentWaypoints.map((wp: Waypoint, index: number) =>
					<WaypointEntry
						isHome={index === 0}
						key={wp.municipality || wp.ordinal}
						onDelete={(wp.ordinal > 1 && (waypoints || []).length > 2) ? () => delWaypoint(wp.ordinal) : undefined}
						waypoint={wp}
						updateWaypoint={updateWaypoint}
						readOnly={readOnly}
						disabled={readOnly || sitesSelected}
						route={route || null}
						setShowModalFor={setShowModalFor}
						numWaypoints={currentWaypoints.length}
						onMove={moveWaypoint}
						firstBlankOrdinal={firstBlankOrdinal}
						isReady={isReady}
					/>
				)}

						{readOnly &&
							<Alert variant="warning" style={{ textAlign: "center", fontSize: "10pt", height: "30px", padding: "5px" }}>
								Jos haluat tehdä muutoksia, valitse &quot;Uusi reitti&quot;.
							</Alert>
						}
						{!readOnly && !isReady &&
							<div style={{ width: "100%", paddingTop: "5px", height: "70px" }}>
								<Button type="button"  size="lg" variant="success" disabled={newRouteNeeded || loading || currentWaypoints.length >= 11} onClick={addWaypoint} style={{ float: "left" }}>
									<div style={{ width: "112px" }}>
										<IoAdd /> <MdLocationCity />
									</div>
								</Button>

								<Button
									variant={(!waypointsDirty && error) ? "danger" : "success"}
									size="lg"
									type="submit"
									disabled={newRouteNeeded || loading || (!waypointsDirty && route !== null) || !waypoints || waypoints.some((wp: Waypoint) => !wp.municipality)}
									style={{ float: "right" }}
								>
									<div style={{ width: "100px" }}>
										{
											(loading && <Spinner size="sm" />)
											|| ((waypointsDirty || route === null) && "Hae reitti")
											|| (error && "Virhe")
											|| <IoCheckmark />
										}
									</div>
								</Button>
							</div>
						}
						{(isPublic || newRouteNeeded) &&
							<div style={{ width: "100%", paddingTop: "5px", height: "70px" }}>
								{isPublic &&
									<OverLayTrigger 
											delay={2000}
											trigger={["click", "hover", "focus"]}
											overlay={
												<Popover id="popover-basic" style={{ border: "10px solid gray"}}>
													<Popover.Header as="h3" style={{backgroundColor: "white"}}><span style={{ color: "green", fontWeight: "bold" }}>Osoite kopioitu leikepöydälle</span></Popover.Header>
												</Popover>
											}
											placement="bottom"
									>
										<Button size="lg" variant="secondary" onClick={() => {navigator.clipboard.writeText(window.location.href);}}>
											Kopioi osoite
										</Button>
									</OverLayTrigger>
								}
								{newRouteNeeded &&
									<Button
										variant="info"
										size="lg"
										type="submit"
										disabled={loading}
										style={{ float: "right" }}
									>
										<div style={{ width: "100px" }}>
											Uusi reitti
										</div>
									</Button>
								}
							</div>
						}
			</Form>

			{maxed && <span></span>}
			{sitesSelected && <span></span>}
			{!waypointsDirty && error &&
				<p style={{color: 'red'}}>
					{error === "retrieve" && "Reitin lataus epäonnistui."}
					{error === "compute" && "Reitin haku epäonnistui. Voit yrittää uudestaan muuttamalla sijaintia (esimerkiksi: kunnan nimi taajaman tilalle)."}
					{error === "maxed" && "Reitin luominen epäonnistui. Sinulla on jo maksimimäärä reittejä. Poista ensin turhia reittejä omilta sivuiltasi."}
				</p>
			}
		</>
	);
}

export default Waypoints;
