import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useGlobalContext from "../common/GlobalContext";

function HelloView(): JSX.Element {
	const goto = useNavigate();
	const { setRoute, setRouteSites, setWaypoints } = useGlobalContext();

	return (
		<Container>
			<Row>
				<Col className="text-center" lg="12" sm="12" md="12">
					<div className="hello-img">
						<img src="nafigaattori.png" />
					</div>
				</Col>
			</Row>
			<Row>
				<Col md="1"></Col>
				<Col md="5">
					{/*
					<h3>Reittiopas</h3>
					Etkö ole vielä päättänyt reittiä? Anna meidän auttaa!
					<Button variant="primary">
						Reittioppaaseen
					</Button>
					*/}
					<div className="textblock">
						<h2>Tervetuloa!</h2>
						<p>
							Nafigaattori.fi on kotimaan matkailuun suunniteltu työkalu. Kun
							tiedät, mistä olet tulossa ja minne menossa, Nafigaattori auttaa
							sinua löytämään mielenkiintoisia kohteita matkan varrelta.
						</p>
						<p>
							Huomio:{" "}
							<span style={{ color: "red" }}>
								kyseessä on alpha- eli testiversio
							</span>
							, jossa esiintyy vielä virheitä. Nafigaattoria kehitetään
							vapaaehtoisvoimin, rajallisilla resursseilla. Pahoittelemme sekä
							kiitämme kärsivällisyydestä!
						</p>
					</div>
				</Col>
				<Col md="5">
					<div className="textblock">
						<h2>Reitin valinta</h2>
						<p>Aloita matkan suunnittelu luomalla uusi reitti.</p>
						<Button
							variant="info"
							onClick={() => {
								if (setRoute) setRoute(null);
								if (setRouteSites) setRouteSites(null);
								if (setWaypoints) setWaypoints(null);
								goto("/reitti/uusi");
							}}
						>
							Luo uusi reitti
						</Button>
						<br />
						<br />
						<h2>Lisää kohde</h2>
						<p>Haluatko lisätä uuden kohteen sivulle? Mukaan kelpaavat kaikki kotimaan matkailijoita kiinnostavat kohteet nähtävyyksistä kahviloihin. Aloita kohteen lisääminen kirjautumalla sisään.</p>
						<Button
							variant="info"
							onClick={() => {
								goto("/omat_sivut");
							}}
						>
							Kirjaudu sisään
						</Button>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default HelloView;
