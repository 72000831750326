import { useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import { z } from "zod";
import { useDescription, useTsController, createTsForm } from "@ts-react/form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import useGlobalContext from "../common/GlobalContext";
import { SiteProps } from "../common/Types";

function TextField({ multiline }: { multiline?: boolean }) {
	const {
		field: { onChange, value },
		error,
	} = useTsController<string>();

	const { label } = useDescription();

	return (
		<>
			<Row className="mt-4">
				<Form.Group>
					<Form.Label>{label}</Form.Label>
					<Form.Control
						style={multiline ? { height: "200px" } : {}}
						as={multiline ? "textarea" : "input"}
						onChange={(e) => onChange(e.target.value)}
						value={value ? value : ""}
					/>
				</Form.Group>
				{error && error.errorMessage}
			</Row>
		</>
	);
}

function CheckboxField() {
	const {
		field: { onChange, value },
	} = useTsController<boolean>();

	const { label } = useDescription();

	return (
		<>
			<Row>
				<Form.Group>
					<Form.Check
						label={label}
						checked={value ? value : false}
						onChange={(e: any) => onChange(e.target.checked)}
					/>
				</Form.Group>
			</Row>
		</>
	);
}

function SelectField({ options }: { options: Array<{ label: string, value: string }> }) {

	const { field, error } = useTsController<string>();
	const { label } = useDescription();

	return (
		<Form.Group className="mt-3">
			<Form.Label>{label}</Form.Label>
			<br />
			<ButtonGroup>
				{options.map(option => {
					const { label, value } = option;
					return (
						<Button variant={field.value === value ? "success" : "secondary"} key={value} onClick={() => field.onChange(value)}>
							{label}
						</Button>
					)
				})}
			</ButtonGroup>
			{error && error.errorMessage}
		</Form.Group>
	);
}

const YearEnum = z.enum(['year', 'summer', 'winter'])
const CustomerEnum = z.enum(['everyone', 'adults', 'children'])

const SiteSchema = z.object({
	name: z.string().describe('Kohteen nimi*'),

	description: z.string().describe('Kohteen kuvaus*'),
	keywords: z.string().optional().describe('Avainsanat (pilkulla erotettuna)'),

	// images: z.string().optional(),
	urls: z.string().optional().describe('WWW-osoite'),

	address: z.object({
		street: z.string().optional().describe('Kadun nimi'),
		street_num: z.string().optional().describe('Katunumero'),
		postalcode: z.string().optional().describe('Postinumero'),
		municipality: z.string().describe('Kunta*'),
		village: z.string().optional().describe('Kylä tai taajama'),
	}),

	class_recreation: z.boolean().optional().describe('Ajanviete'),
	class_experience: z.boolean().optional().describe('Elämykset'),
	class_culture: z.boolean().optional().describe('Kulttuuri'),
	class_nature: z.boolean().optional().describe('Luonto'),
	class_accommodation: z.boolean().optional().describe('Majoitus'),
	class_travel: z.boolean().optional().describe('Matkapalvelut'),
	class_attraction: z.boolean().optional().describe('Nähtävyys'),
	class_shopping: z.boolean().optional().describe('Ostokset'),
	class_nutrition: z.boolean().optional().describe('Ruoka ja juoma'),

	accessible: z.boolean().optional().describe('Esteetön kohde'),
	petfriendly: z.boolean().optional().describe('Lemmikit sallittu'),
	costless: z.boolean().optional().describe('Maksuton kohde'),

	season: YearEnum.optional().describe('Sesonki'),
	customer: CustomerEnum.optional().describe('Kohderyhmä'),

	published: z.boolean().optional().describe('Julkaistu'),
});

const mapping = [
	[z.string(), TextField] as const,
	[z.boolean(), CheckboxField] as const,
	[z.enum(['fake']), SelectField] as const,
] as const;

const MyForm = createTsForm(mapping);

function SiteEditor({ uuid, onClose, onReload }: { uuid: string, onClose: () => void, onReload: () => void }) {
	const { client } = useGlobalContext();
	const [error, setError] = useState(false);
	const [site, setSite] = useState<SiteProps | null | undefined>(undefined);

	const [retrieveSiteState, retrieveSite] = useAsyncFn(async (uuid: string) => {
		try {
			const { data } = await client.get(`/sites/${uuid}/`);
			setSite(data);
		} catch {
			setError(true);
		}
	});

	useEffect(() => {
		if (uuid) {
			retrieveSite(uuid);
		}
		else {
			setSite(null);
		}
	}, [uuid])

	const [uploadDataState, uploadData] = useAsyncFn(async (data: any) => {
		try {
			const response = await (uuid ? client.patch(`/sites/${uuid}/`, data) : client.post("/sites/", data));
			if (response.status === 200) {
				onClose();
				onReload();
			}
		} catch {
			setError(true);
		}
	});

	function onSubmit(data: z.infer<typeof SiteSchema>) {
		uploadData(data);
	}

	return (
		<Modal scrollable={true} fullscreen="md-down" className="bg-blue" size="lg" show={true} onHide={onClose}>
			<Modal.Header closeButton>
				{site && site.name ? `Muokkaa kohdetta: ${site.name}` : "Uusi kohde"}
			</Modal.Header>
			<Modal.Body>
				{error &&
					<div>
						<span style={{ color: 'red' }}>Lähetyksessä tapahtui virhe. Tarkista osoite ja yritä uudestaan.</span>
					</div>
				}
				{site === undefined || retrieveSiteState.loading
					? <Spinner />
					:
					<>
						<MyForm
							defaultValues={{
								name: site && site.name || '',
								address: site && site.address
									? {
										street: site.address.street || '',
										street_num: site.address.street_num || '',
										postalcode: site.address.postalcode || '',
										municipality: site.address.municipality || '',
										village: site.address.village || '',
									}
									: {},
								description: site && site.description || '',

								urls: site && Array.isArray(site.urls) ? site.urls.join(',') : '',
								keywords: site && Array.isArray(site.keywords) ? site.keywords.join(',') : '',

								class_recreation: site && site.class_recreation || false,
								class_experience: site && site.class_experience || false,
								class_culture: site && site.class_culture || false,
								class_nature: site && site.class_nature || false,
								class_accommodation: site && site.class_accommodation || false,
								class_travel: site && site.class_travel || false,
								class_attraction: site && site.class_attraction || false,
								class_shopping: site && site.class_shopping || false,
								class_nutrition: site && site.class_nutrition || false,

								accessible: site && site.accessible || false,
								petfriendly: site && site.petfriendly || false,
								costless: site && site.costless || false,

								season: YearEnum.parse(site && site.season || 'year'),
								customer: CustomerEnum.parse(site && site.customer || 'everyone'),

								published: site && site.published || false,
							}}
							schema={SiteSchema}
							onSubmit={onSubmit}
							renderAfter={() =>
								<div>
									<Button
										disabled={uploadDataState.loading}
										className="mt-3"
										type="submit"
									>
										{uploadDataState.loading ? <Spinner /> : "Lähetä"}
									</Button>
									<Button
										style={{ float: "right" }}
										variant="danger"
										disabled={uploadDataState.loading}
										className="mt-3"
										type="button"
										onClick={onClose}
									>
										{uploadDataState.loading ? <Spinner /> : "Peruuta"}
									</Button>
								</div>
							}
							props={{
								description: {
									multiline: true,
								},
								customer: {
									options: [
										{ label: 'Kaikki', value: 'everyone' },
										{ label: 'Aikuiset', value: 'adults' },
										{ label: 'Lapset', value: 'children' },

									],
								},
								season: {
									options: [
										{ label: 'Ympärivuotinen', value: 'year' },
										{ label: 'Kesä', value: 'summer' },
										{ label: 'Talvi', value: 'winter' },
									],
								},
							}}
						>
							{({ name, address, description, urls, keywords, class_recreation, class_culture,
								class_nature, class_accommodation, class_attraction, class_shopping, class_nutrition,
								class_travel, class_experience, accessible, petfriendly, costless, season, customer, published }) => {
								return (
									<>
										<h3>Perustiedot</h3>
										<h6>* vaaditut kentät</h6>
										{name}
										{description}
										<hr className="mt-3" />
										<h3>Osoite</h3>
										{address.street}
										{address.street_num}
										{address.postalcode}
										{address.municipality}
										{address.village}
										<hr className="mt-3" />
										<h3>Lisätiedot</h3>
										{accessible}
										{petfriendly}
										{costless}
										{urls}
										{keywords}
										{season}
										{customer}
										<hr className="mt-3" />
										<h3>Luokat</h3>
										<p>Määritä kohteen pääasialliset luokat (suositus 1-3 kpl)</p>
										{class_recreation}
										{class_experience}
										{class_culture}
										{class_nature}
										{class_accommodation}
										{class_travel}
										{class_attraction}
										{class_shopping}
										{class_nutrition}
										<hr className="mt-3" />
										<h3>Kohteen näkyvyys</h3>
										{published}
										<p className="mt-1">Julkaistu kohde on kaikkien nähtävissä.<br />Julkaisemattoman kohde ei näy muille.</p>
									</>
								);
							}}
						</MyForm>
					</>
				}
			</Modal.Body>
		</Modal>
	);
}

export default SiteEditor;
