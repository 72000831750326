import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import YesNo from "./YesNo";

function Disclaimer({ onAccept }:{ onAccept: () => void }): JSX.Element {
	const goto = useNavigate();

	return (
		<Modal show={true}>
			<Modal.Header>
				<h3>
					Huomautus
				</h3>
			</Modal.Header>
			<Modal.Body>
				<p>
					<span style={{ color: 'red' }}> Huomio!</span> Muistathan, että Nafigaattorin ilmoittamat tiedot kuten osoitteet, etäisyydet ja esteettömyys ovat suuntaa antavia. Varmistathan tietojen oikeellisuuden aina virallisista lähteistä.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<YesNo onYes={onAccept} yesText="Hyväksyn" onNo={() => goto('/')} noText="En hyväksy" />
			</Modal.Footer>
		</Modal>
	);
}

export default Disclaimer;
