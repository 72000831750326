import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useAsyncFn } from "react-use";
import { IoEye, IoTrash } from "react-icons/io5";
import YesNo from "./YesNo";
import useGlobalContext from "../common/GlobalContext";
import { RouteProps } from "../common/Types";

function UserRoutes(): JSX.Element {
	const {
		client,
		route,
		setRoute,
		setRouteSites,
	} = useGlobalContext();
	const goto = useNavigate();
	const [userRoutes, setUserRoutes] = useState<Array<RouteProps> | null>(null);
	const [deletedRoute, setDeletedRoute] = useState<RouteProps | null>(null);
	const [loadedRoute, setLoadedRoute] = useState<RouteProps | null>(null);

	const [loadDataState, loadData] = useAsyncFn(async () => {
		const { data } = await client.get("/routes/");
		setUserRoutes(data);
	}, [userRoutes, setUserRoutes]);

	const [deleteState, deleteRoute] = useAsyncFn(async () => {
		if (deletedRoute) {
			const response = await client.delete(`/routes/${deletedRoute.uuid}/`);
			if (response.status === 204) {
				setDeletedRoute(null);
				setUserRoutes(null);
			}
		}
	}, [userRoutes, setUserRoutes, deletedRoute]);

	useEffect(() => {
		if (
			!userRoutes &&
			!loadDataState.loading &&
			!loadDataState.error
		) {
			loadData();
		}
	}, []);

	if (!userRoutes || loadDataState.loading || deleteState.loading) {
		return <Spinner />;
	}

	return (
		<Container>
			<Modal show={Boolean(deletedRoute)} onHide={() => setDeletedRoute(null)}>
				<Modal.Header>Poista reitti</Modal.Header>
				<Modal.Body>
					<p>
						Haluatko poistaa reitin{" "}
						{deletedRoute && (deletedRoute.name || deletedRoute.uuid)}?{" "}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<YesNo onYes={deleteRoute} onNo={() => setDeletedRoute(null)} />
				</Modal.Footer>
			</Modal>
			<Modal show={Boolean(loadedRoute)} onHide={() => setLoadedRoute(null)}>
				<Modal.Header>
					<h3>Tarkastele reittiä</h3>
				</Modal.Header>
				<Modal.Body>
					<p>
						Haluatko tarkastella reittiä{" "}
						{loadedRoute && (loadedRoute.name || loadedRoute.uuid)}?{" "}
					</p>
					{route && route.uuid && (
						<p>
							Nykyisen reitin tallentamattomat tiedot poistetaan. Muistathan
							halutessasi tallentaa nykyisen reitin ennen toisen reitin
							tarkatelua.
						</p>
					)}
				</Modal.Body>
				<Modal.Footer>
					<YesNo
						onYes={() => {
							if (loadedRoute) {
								if (setRoute) setRoute(null);
								if (setRouteSites) setRouteSites(null);
								goto(`/reitti/${loadedRoute.uuid}`);
							}
						}}
						onNo={() => setLoadedRoute(null)}
					/>
				</Modal.Footer>
			</Modal>
			<Row>
				<Col>
					<h3>Tallennetut reitit:</h3>
					{userRoutes && userRoutes.length > 0 ? (
						<>
							<Table>
								<thead>
									<tr>
										<th>#</th>
										<th>Reitti</th>
										<th>Tarkastele</th>
										<th>Poista</th>
									</tr>
								</thead>
								<tbody>
									{userRoutes.map((r: RouteProps, index: number) => (
										<tr key={r.uuid}>
											<td>{index + 1}</td>
											<td>
												{r.name ||
													`Nimetön reitti (id: ${(r.uuid || "").substring(
														0,
														5,
													)}...)`}
											</td>
											<td>
												<Button
													variant="success"
													onClick={() => setLoadedRoute(r)}
												>
													<IoEye />
												</Button>
											</td>
											<td>
												<Button
													variant="danger"
													onClick={() => setDeletedRoute(r)}
												>
													<IoTrash />
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							<p>Voit tallentaa enintään viisi reittiä.</p>
						</>
					) : (
						<h3>Sinulla ei ole vielä tallennettuja reittejä.</h3>
					)}
				</Col>
			</Row>
		</Container>
	);
}

export default UserRoutes;
