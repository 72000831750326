import axios from "axios";

class Client {
	instance: any;
	authString: string;
	baseURL?: string;

	constructor(baseURL?: string) {
		this.instance = axios.create({
			baseURL: baseURL,
			timeout: 8000,
			headers: {
				Accept: 'application/json',
			},
			params: {},
		});
		this.authString = '';
		this.baseURL = baseURL;
	}

	setAuth = (authString: string): void => {
		this.authString = authString;
		if (authString) {
			// this.instance.interceptors.request.use(
			// 	(config: AxiosRequestConfig) => {
			// 		if (config && config.headers)
			// 			config.headers['Authorization'] = authString;
			// 		return config;
			// 	},
			// 	(error: AxiosError) => {
			// 		return Promise.reject(error)
			// 	}
			// )
			this.instance.defaults.headers.common['Authorization'] = authString;
		}
		else {
			//this.instance.interceptors.request.eject();
			delete this.instance.defaults.headers.common['Authorization'];
		}
	}

	get = async (path: string) => {
		return await this.instance.get(path);
	}

	delete = async (path: string) => {
		return await this.instance.delete(path);
	}

	patch = async (path: string, params: any) => {
		return await this.instance.patch(path, params);
	}

	post = async (path: string, params: any) => {
		return await this.instance.post(path, params);
	}
}


export default Client;
