import { useState } from "react";
import { useAsyncFn } from "react-use";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";

import useGlobalContext from '../common/GlobalContext';
import { SiteProps } from "../common/Types";

function SiteSearch({onModifySite, onSelectSite}:{onModifySite?: (site: SiteProps) => void, onSelectSite: (name: string, municipality: string, uuid: null | string) => void}): JSX.Element {
	const { client } = useGlobalContext();
	const [ searchedSiteName, setSearchedSiteName ] = useState('');
	const [ searchedSiteMunicipality, setSearchedSiteMunicipality ] = useState('');
	const [ foundSites, setFoundSites ] = useState<null | Array<object>>(null);

	const [ searchSiteState, searchSite ] = useAsyncFn(async () => {
		const params = `name=${searchedSiteName}${searchedSiteMunicipality ? `&municipality=${searchedSiteMunicipality}` : ''}`;
		const { data } = await client.get(`/sites/?${params}`);
		setFoundSites(data.results);
	}, [searchedSiteName, searchedSiteMunicipality]);

	return (
		<Col sm={{ span: 4, offset: 4}}>
			<Row>
				<h1>Kohteen tiedot</h1>
				<p></p>
				<Form className="mt-3">
					<Form.Label>Nimi</Form.Label>
					<Form.Control placeholder="Syötä kohteen nimi" value={searchedSiteName || ''} onChange={(e: React.BaseSyntheticEvent) => {setFoundSites(null); setSearchedSiteName(e.target.value);}}/>
					<Form.Label>Sijainti</Form.Label>
					<Form.Control placeholder="Sijainti (kunta, kaupunki, jne.)" value={searchedSiteMunicipality || ''} onChange={(e: React.BaseSyntheticEvent) => {setFoundSites(null); setSearchedSiteMunicipality(e.target.value);}}/>
					<Button disabled={searchedSiteName.length < 2 || searchedSiteMunicipality.length < 2 || searchSiteState.loading} type="submit" className="mt-3" onClick={(e: React.BaseSyntheticEvent) => {e.preventDefault(); searchSite();}}>{searchSiteState.loading ? <Spinner /> : (foundSites && foundSites.length > 0 ? "Uusi haku" : "Jatka")}</Button>
				</Form>
			</Row>
			{foundSites !== null &&
				<Row className="mt-3">
					{foundSites.length === 0
						? <span>Emme löytäneet aiempia kohteita. Voit ehdottaa uutta kohdetta alta.</span>
						: <>
								<h5>Nafigaattorista löytyviä kohteita</h5>
								<h6>Valitse kohde päivittääksesi tietoja</h6>
								<Table borderless>
									<tbody>
										{foundSites.map((site: any) =>
										{
											const location = site.village && site.municipality ? `${site.village} / ${site.municipality}` : site.village || site.municipality;
											return <tr key={site.uuid}>
												<td className="pt-3 text-center">
													<Button
														size="lg"
														style={{ maxWidth: "400px", width: "100%" }}
														variant="info"
														onClick={() => onSelectSite(site.name, site.municipality, site.uuid)}
													>
															{site.name}<br/>{location}
													</Button>
												</td>
											</tr>;
										}
										)}
									</tbody>
								</Table>
							</>
					}
					{onModifySite !== undefined &&
						<div className="mt-3">
							{foundSites && foundSites.length > 0 ? <h5>Eikö mikään tuloksista vaikuttanut oikealta?</h5> : ""}
							<br/>
							<Button 
									size="lg"
									style={{ maxWidth: "400px", width: "100%" }}
									onClick={() => onModifySite({uuid: '', name: searchedSiteName, address: { municipality: searchedSiteMunicipality, lat: 0, lon: 0}, customer: 'everyone', season: 'year', petfriendly: false, accessible: false } )}
								>
								Uusi kohde<br/>{searchedSiteName}
							</Button>
						</div>
					}
				</Row>
			}
		</Col>
	);
}

export default SiteSearch;
