import { IoImage } from "react-icons/io5";
import SiteAttributes from "../common//SiteAttributes";
import { SiteProps } from "../common/Types";

export const getTravelledDistance = (distance: number): number => Math.round(distance / 10) * 10;

export const getApproximateDistanceFromRoute = (distance: number): string => {
	const min = Math.floor(distance / 10) * 10;
	return `${min} - ${min + 10}`
}

export const getCenter = (coords: Array<Array<number>>): [number, number] => {
	const sumLat = coords.map((c: Array<number>) => c[0]).reduce((a, b) => a + b, 0);
	const avgLat = (sumLat / coords.length) || 0.0;
	const sumLon = coords.map((c: Array<number>) => c[1]).reduce((a, b) => a + b, 0);
	const avgLon = (sumLon / coords.length) || 0.0;
	return [avgLat, avgLon];
}

export const getCoords = (data: any): any => {
	return (data['step_lat'].map((lat: number, idx: number) => [lat, data['step_lon'][idx]]));
}

export const getPlaceholderImage = (site: SiteProps): JSX.Element => {
	let attr = undefined;
	let color = 'white';
	if  (site.class_attraction) {
		attr = SiteAttributes.find((attr: any) => attr.attribute === "class_attraction");
		color = 'navy';
	}
	else if  (site.class_culture) {
		attr = SiteAttributes.find((attr: any) => attr.attribute === "class_culture");
		color = 'orange';
	}
	else if  (site.class_recreation) {
		attr = SiteAttributes.find((attr: any) => attr.attribute === "class_recreation");
		color = 'yellow';
	}
	else if  (site.class_shopping) {
		attr = SiteAttributes.find((attr: any) => attr.attribute === "class_shopping");
		color = 'purple';
	}
	else if  (site.class_nature) {
		attr = SiteAttributes.find((attr: any) => attr.attribute === "class_nature");
		color = 'lightgreen';
	}
	return <span style={{ color: color }}>{attr ? attr.icon : <IoImage />}</span>;
}
