import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useGlobalContext from "../common/GlobalContext";

function UserInfo(): JSX.Element {
	const {
		user,
	} = useGlobalContext();
	const goto = useNavigate();

	return (
		<Container>
			<Row>
				<Col>
					<h1>
						Hei, {user && user.email ? user.email : ""}!
					</h1>
					<p className="mt-5">
						<h3>Haluatko tarkastella <span style={{ color: 'orange' }}>oma reittejäsi?</span></h3>
						Valitse yläpuolelta <strong>omat reitit</strong>.
					</p>
					<p className="mt-5">
						<h3>Haluat lisätä kohteen tai muokata <span style={{ color: 'red' }}>omaa kohdettasi?</span></h3>
						Valitse yläpuolelta <strong>omat kohteet</strong>.
					</p>
					<p className="mt-5">
						<h3>Käyttäjätiedot</h3>
						Tällä hetkellä tallennamme ainoastaan sähköpostiosoitteesi.
						<br />
						<br />
						<Button variant="info" onClick={() => goto('/tietosuoja')}>
							Tietosuojaseloste.
						</Button>
					</p>
				</Col>
			</Row>
		</Container>
	);
}

export default UserInfo;
