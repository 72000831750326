import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner"; 

import useGlobalContext from "../common/GlobalContext";
import SiteDetails from "../components/SiteDetails";
import SiteSearch from "../components/SiteSearch";
import SiteSuggestion from "../components/SiteSuggestion";
import { SiteProps } from "../common/Types";

function SitesView({isHometown}:{isHometown?:boolean}): JSX.Element {
	const { client } = useGlobalContext();
	const [ shownSite, setShownSite ] = useState<null | SiteProps>(null);
	const [ modifiedSite, setModifiedSite ] = useState<null | SiteProps>(isHometown ? { name: '', address: { municipality: '', lat: 0, lon: 0}, uuid: ''} : null);
	const { pathUUID } = useParams();
	const goto = useNavigate();

	const [ loadSiteState, loadSite ] = useAsyncFn(async (uuid: string) => {
		try {
			const { data } = await client.get(`/sites/${uuid}/`);
			setModifiedSite(data);
		} catch (error) {
			setModifiedSite(null);
			goto('/kohteet');
		}
	}, [modifiedSite, setModifiedSite]);

	useEffect(() => {
		if (!isHometown) {
			if (modifiedSite && modifiedSite.uuid !== pathUUID)
				goto(`/kohteet/${modifiedSite.uuid}`);
			if (!modifiedSite && pathUUID && pathUUID !== 'uusi') {
				loadSite(pathUUID);
			}
		}
	}, [modifiedSite, pathUUID]);

	if (loadSiteState.loading) {
		return (
			<Col>
				<Spinner />
			</Col>
		)
	}
	return (
		<Col>
			{shownSite && 
				<SiteDetails
					onModify={(site: SiteProps) => setModifiedSite(site)}
					onClose={() => setShownSite(null)} uuid={shownSite.uuid} 
				/>
			}
			{modifiedSite
				? <SiteSuggestion isHometown={isHometown || false} site={modifiedSite} onCancel={() => {setModifiedSite(null); goto(isHometown ? '/kotiseutu' : '/kohteet');}}/>
				:	<SiteSearch
						onModifySite={(site: SiteProps) => setModifiedSite(site)}
						onSelectSite={(name: string, municipality: string, uuid: string | null) => 
							setShownSite({ name, address: {municipality, lat: 0, lon: 0}, uuid: uuid || ''})
						}
					/>
			}
		</Col>
	);
}

export default SitesView;
