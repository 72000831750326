import Table from 'react-bootstrap/Table';
import SiteAttributes from "../common/SiteAttributes";
import { SiteProps } from "../common/Types";

function SiteIcons({site, size}:{site: SiteProps, size?: string}) {
	if (size === 'compact') {
		return (
			<div className="site-icons">
				{SiteAttributes.map((sa: any) => {
					if (site[sa.attribute as keyof SiteProps] === sa.condition) {
							return (
								<span key={sa.attribute} className={"site-icon-dense"}>
									{sa.icon}
								</span>
							);
					}
					else
						return null;
				})}
			</div>
		);
	}
	else {
		const icons: Array<JSX.Element> = [];
		const texts: Array<JSX.Element> = [];

		SiteAttributes.forEach((sa: any) => {
			if (site[sa.attribute as keyof SiteProps] === sa.condition) {
				icons.push(
						<div className="site-icon-sparse">
							{sa.icon}
						</div>
				);
				texts.push(
						<div className="">
							{sa.text}
						</div>
				)
			}
		})
			return (
				<div className="icons-table">
					<Table>
						<tbody>
							<tr className="site-details-attribute-icon">
								{icons.map((icon: any, idx: number) => {return <td key={idx}>{icon}</td>})}
							</tr>
							<tr className="site-details-attribute-text">
								{texts.map((icon: any, idx: number) => {return <td key={idx}>{icon}</td>})}
							</tr>
						</tbody>
					</Table>
				</div>
			);
	}

}

export default SiteIcons;
