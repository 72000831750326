import React from "react";
import Col from "react-bootstrap/Col";

function PrivacyStatement(): JSX.Element {
	return (
		<Col>
			<h1>
				Rekisteri- ja tietosuojaseloste
			</h1>

			<p>
				Tämä on Nafigaattorin EU:n yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste. Laadittu 20.6.2023.
			</p>

			<p>
				Seloste on laadittu käyttäen apuna osoitteessa https://www.innowise.fi/fi/gdprn-mukainen-rekisteri-ja-tietosuojaselosteen-malli/ vapaasti käytettävissä olevaa mallia hyödyntäen.
			</p>

			<h4>
				1. Rekisterinpitäjä
			</h4>

			<p>Harrastussivusto Nafigaattori, www.nafigaattori.fi. Sivustolla ei ole fyysistä osoitetta tai Y-tunnusta.</p>


			<h4>
				2. Rekisteristä vastaava yhteyshenkilö
			</h4>

			<p>Timo Jokela, nafigaattori at-merkki gmail piste com.</p>

			<h4>
				3. Rekisterin nimi
			</h4>

			<p>Nafigaattorin käyttäjärekisteri.</p>

			<h4>
				4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus
			</h4>

			<p>
				EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle on henkilön suostumus (dokumentoitu, vapaaehtoinen, yksilöity, tietoinen ja yksiselitteinen). Henkilö on omaehtoisesti ilmoittanut tallennetut tiedot Nafigaattorille.
			</p>
			<p>
				Henkilötietojen käsittelyn tarkoitus tiedottaminen (uudet ominaisuudet, kilpailuiden tai arvontojen voitot).
			</p>

			<p>
				Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin. 
			</p>

			<h4>
				5. Rekisterin tietosisältö
			</h4>

			<p>
				Rekisteriin tallennettavia tietoja ovat: sähköpostiosoite, suostumus tiedotteiden vastaanottamiseen.
			</p>

			<h4>
				6. Säännönmukaiset tietolähteet
			</h4>

			<p>
				Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä.
			</p>

			<h4>
				7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle
			</h4>

			<p>
				Tietoja ei luovuteta muille tahoille tai siirretä EU:n ulkopuolelle. Poikkeuksen aiheuttaa viranomaisen asettama vaatimus.
			</p>

			<h4>
				8. Rekisterin suojauksen periaatteet
			</h4>

			<p>
				Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti. Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
			</p>

			<h4>
				9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista
			</h4>

			<p>
				Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
			</p>

			<h4>
				10. Muut henkilötietojen käsittelyyn liittyvät oikeudet
			</h4>

			<p>
				Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien henkilötietojen poistamiseen rekisteristä (&quot;oikeus tulla unohdetuksi&quot;). Niin ikään rekisteröidyillä on muut EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet kuten henkilötietojen käsittelyn rajoittaminen tietyissä tilanteissa. Pyynnöt tulee lähettää kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
			</p>

		</Col>		
	)
}

export default PrivacyStatement;
