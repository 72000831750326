import { useEffect, useState } from "react";
import { GlobalContext } from "./common/GlobalContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router-dom";

import Client from "./common/Client";
import {
	RouteSiteProps,
	RouteProps,
	UserProps,
	WaypointsContainer,
} from "./common/Types";
import TopNav from "./components/TopNav";
import AboutView from "./views/AboutView";
import HelloView from "./views/HelloView";
import RouteView from "./views/RouteView";
import SitesView from "./views/SitesView";
import UserView from "./views/UserView";
import PrivacyStatement from "./components/PrivacyStatement";
import useGlobalContext from "./common/GlobalContext";

import "./App.css";
import "./Color.css";

export const APIURL = new URL(process.env.REACT_APP_APIURL || "");
export const TILEURL = new URL(process.env.REACT_APP_TILEURL || "");

SuperTokens.init({
	appInfo: {
		appName: "Nafigaattori",
		apiDomain: process.env.REACT_APP_SUPERTOKENS_APIDOMAIN || "",
		websiteDomain: process.env.REACT_APP_SUPERTOKENS_WEBSITEDOMAIN || "",
		apiBasePath: "/api/auth",
		websiteBasePath: "/auth",
	},
	languageTranslations: {
		translations: {
			fi: {

				GENERAL_ERROR_EMAIL_UNDEFINED: "Aseta sähköpostiosoitteesi",
				GENERAL_ERROR_EMAIL_NON_STRING: "Sähköpostiosoitteen on oltava merkkijono",
				GENERAL_ERROR_EMAIL_INVALID: "Sähköpostiosoite on virheellinen",

				GENERAL_ERROR_PHONE_UNDEFINED: "Please set your phone number",
				GENERAL_ERROR_PHONE_NON_STRING: "Phone number must be of type string",
				GENERAL_ERROR_PHONE_INVALID: "Phone number is invalid",

				GENERAL_ERROR_EMAIL_OR_PHONE_UNDEFINED: "Please set your email or phone number",
				GENERAL_ERROR_EMAIL_OR_PHONE_NON_STRING: "Email or Phone number must be of type string",
				GENERAL_ERROR_EMAIL_OR_PHONE_INVALID: "Email or Phone number is invalid",

				GENERAL_ERROR_OTP_UNDEFINED: "Please fill your OTP",
				GENERAL_ERROR_OTP_INVALID: "Virheellinen koodi",
				GENERAL_ERROR_OTP_EXPIRED: "Vanhentunut koodi.",
				GENERAL_ERROR_OTP_NON_STRING: "Koodin on oltava merkkijono",
				GENERAL_ERROR_OTP_EMPTY: "Koodi ei voi olla tyhjä",

				ERROR_SIGN_IN_UP_LINK: "Invalid magic link. Please try again.",
				ERROR_SIGN_IN_UP_RESEND_RESTART_FLOW: "Login timed out. Please try again.",
				ERROR_SIGN_IN_UP_CODE_CONSUME_RESTART_FLOW: "Login unsuccessful. Please try again.",

				PWLESS_CLOSE_TAB_TITLE: "Valmista!",
				PWLESS_CLOSE_TAB_SUBTITLE_LINE1: "Olet onnistuneesti kirjautunut sisään",
				PWLESS_CLOSE_TAB_SUBTITLE_LINE2: "Voit sulkea tämän välilehden",

				PWLESS_SIGN_IN_UP_HEADER_TITLE: "Rekisteröidy tai kirjaudu",

				PWLESS_SIGN_IN_UP_FOOTER_START: "By continuing, you agree to our ",
				PWLESS_SIGN_IN_UP_FOOTER_TOS: "Terms of Service",
				PWLESS_SIGN_IN_UP_FOOTER_AND: " and ",
				PWLESS_SIGN_IN_UP_FOOTER_PP: "Privacy Policy",
				PWLESS_SIGN_IN_UP_FOOTER_END: "",

				PWLESS_SIGN_IN_UP_EMAIL_LABEL: "Sähköpostiosoite",
				PWLESS_SIGN_IN_UP_PHONE_LABEL: "Phone Number",
				PWLESS_SIGN_IN_UP_EMAIL_OR_PHONE_LABEL: "Email or Phone number",
				PWLESS_SIGN_IN_UP_CONTINUE_BUTTON: "OK",
				PWLESS_EMAIL_OR_PHONE_INVALID_INPUT_GUESS_PHONE_ERR: "Please enter a valid phone number with its country code.",

				PWLESS_LINK_SENT_RESEND_SUCCESS: "Link resent",
				PWLESS_LINK_SENT_RESEND_TITLE: "Link sent!",
				PWLESS_LINK_SENT_RESEND_DESC_START_EMAIL: "We sent a link to ",
				PWLESS_LINK_SENT_RESEND_DESC_START_PHONE: "We sent a link to your phone number ",
				PWLESS_LINK_SENT_RESEND_DESC_END_EMAIL: " Click the link to login or sign up",
				PWLESS_LINK_SENT_RESEND_DESC_END_PHONE: "",

				PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL: "Vaihda sähköpostiosoitetta",
				PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_PHONE: "Change phone number",

				PWLESS_LINK_CLICKED_CONTINUE_HEADER: "Kirjaudu isään",
				PWLESS_LINK_CLICKED_CONTINUE_DESC: "Paina nappia jatkaaksesi eteenpäin tällä laitteella",
				PWLESS_LINK_CLICKED_CONTINUE_BUTTON: "OK",

				PWLESS_RESEND_SUCCESS_EMAIL: "Sähköposti lähetetty uudestaan",
				PWLESS_RESEND_SUCCESS_PHONE: "SMS resent",

				PWLESS_RESEND_BTN_DISABLED_START: "Lähetä uudestaan: ",
				PWLESS_RESEND_BTN_DISABLED_END: "",
				PWLESS_RESEND_BTN_EMAIL: "Lähetä uudestaan",
				PWLESS_RESEND_BTN_PHONE: "Resend SMS",

				PWLESS_USER_INPUT_CODE_HEADER_TITLE: "Syötä koodi",
				PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: "Koodi lähetettiin sinulle osoitteeseen",
				PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE_LINK: "An OTP and a magic link was sent to you at",
				PWLESS_USER_INPUT_CODE_INPUT_LABEL: "Koodi",

				/*
				 * The following are error messages from our backend SDK.
				 * These are returned as full messages to preserver compatibilty, but they work just like the keys above.
				 * They are shown as is by default (setting the value to undefined will display the raw translation key)
				 */
				"Failed to generate a one time code. Please try again": undefined,
				"Phone number is invalid": undefined,
				"Email is invalid": undefined,
			}
		},
	},
	recipeList: [
		Passwordless.init({
			contactMethod: "EMAIL",
		}),
		Session.init(),
	],
});
SuperTokens.changeLanguage('fi');

function WrappedApp() {
	const { client, setUser } = useGlobalContext();

	useEffect(() => {
		async function loadUser() {
			try {
				const { data } = await client.get("/user/me/");
				if (setUser) setUser({ email: data.email });
			} catch {
				if (setUser) setUser(null);
			}
		}

		loadUser();
	}, []);

	return (
		<BrowserRouter>
			<Container id="AppContainer" fluid>
				<Row>
					<TopNav />
				</Row>
				<Row id="main-content">
					<Routes>
						{getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
							PasswordlessPreBuiltUI,
						])}
						<Route path="/" element={<HelloView />} />
						<Route path="/reitti/:pathUUID" element={<RouteView />} />
						<Route path="/info" element={<AboutView />} />
						<Route path="/omat_sivut" element={<UserView />} />
						<Route
							path="/kotiseutu"
							element={<SitesView isHometown={true} />}
						/>
						<Route path="/kohteet" element={<SitesView />} />
						<Route path="/kohteet/:pathUUID" element={<SitesView />} />
						<Route path="/tietosuoja" element={<PrivacyStatement />} />
					</Routes>
				</Row>
			</Container>
		</BrowserRouter>
	)
}

function App() {
	const [client] = useState<Client>(new Client(APIURL.toString()));
	const [route, setRoute] = useState<RouteProps | null>(null);
	const [routeSites, setRouteSites] = useState<RouteSiteProps[] | null>(null);
	const [user, setUser] = useState<UserProps | null>(null);
	const [waypoints, setWaypoints] = useState<WaypointsContainer | null>(null);

	return (
		<SuperTokensWrapper>
			<GlobalContext.Provider
				value={{
					client,
					route,
					setRoute,
					routeSites,
					setRouteSites,
					user,
					setUser,
					waypoints,
					setWaypoints,
				}}
			>
				<WrappedApp />
			</GlobalContext.Provider>
		</SuperTokensWrapper>
	);
}

export default App;
