import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from 'react-bootstrap/Table';
import ExternalDependencies from "../common/ExternalDependencies";
import Modal from 'react-bootstrap/Modal';

const dependencies: Array<Array<string>> = [];
(new Map(Object.entries(ExternalDependencies))).forEach((v: any, k: string) => {
	const row = [(k.startsWith('@') ? k.slice(1) : k).split('@')[0], v.licenses || 'N/A', v.repository || 'N/A', v.publisher || 'N/A'];
	const keys = dependencies.map((d: any) => d[2]);
	if (!keys.includes(row[2]))
		dependencies.push(row);
});

function AboutView(): JSX.Element {
	const [ showExternalDependencies, setShowExternalDependencies ] = useState(false);
	if (showExternalDependencies) {
		return (
			<Modal size="lg" show={true} fullscreen={true} onHide={() => setShowExternalDependencies(false)}>
				<Modal.Header closeButton>
					<h3>External software components</h3>
				</Modal.Header>
				<Modal.Body>
					<p>This website uses base map data from <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> and <a href="https://osmfoundation.org/">OpenStreetMap foundation</a>.</p>
					<p>Additionally, this website relies on the following open-source components. These components are the intellectual property of their respective owners.</p>
					<Table>
						<thead>
							<tr>
								<th>
									Component
								</th>
								<th>
									License
								</th>
								<th>
									Repository
								</th>
								<th>
									Publisher
								</th>
							</tr>
						</thead>
						<tbody>
							{dependencies.map((d, index) => {
									const licenseURL = '/licenses/' + d[2].replace('https://github.com/', '').replace('/', '-') + '.txt';
									return (
										<tr key={index}>
											<td>
												{d[0]}
											</td>
											<td>
												<a href={licenseURL}>{d[1]}</a>
											</td>
											<td>
												<a href={d[2]}>{d[2]}</a>
											</td>
											<td>
												{d[3]}
											</td>
										</tr>
								)})
							}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
		);
	}
	return (
		<Container>
			<Row>
				<h3>Yleistä tietoa</h3>
				<p>
					Nafigaattori.fi on kotimaan matkailuun suunniteltu apuväline. Se etsii mielenkiintoisia matkakohteita käyttäjän matkareitin varrelta.
				</p>
				<p>
					Kyseessä on varhaisessa kehitysvaiheessa oleva harrastelijavoimin ylläpidetty sivusto. Uusia ominaisuuksia toteutetaan ja nykyisiä virheitä korjataan käytettävissä olevien resurssien mukaan.
				</p>
				<p><strong>Kaikki sivustolla ilmoitetut tiedot ovat ohjeellisia.</strong> Tietojen oikeellisuus on aina syystä tarkistaa virallisista lähteistä.</p>
				<p>Rekisteri- ja tietosuojaseloste on luettavissa <Link to="/tietosuoja">täällä</Link>.</p>
			</Row>
			<Row>
				<div>
					<h3>License information</h3>
					<p>for English speaking viewers</p>
					<Button onClick={() => setShowExternalDependencies(true)}>
						Dependencies
					</Button>
				</div>
			</Row>
		</Container>
	);
}

export default AboutView;
