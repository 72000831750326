import { useCallback, useState } from "react";
import Button from 'react-bootstrap/Button';
import Collapse from "react-bootstrap/Collapse";
import useGlobalContext from "../common/GlobalContext";
import { RouteSiteProps } from "../common/Types";
import SiteCard from "./SiteCard";
import SiteDetails from "./SiteDetails";


function RouteSites(
		{highlightedUUID, setHighlightedUUID, readOnly}
		:{highlightedUUID: string | null; setHighlightedUUID: (uuid: string | null) => void; readOnly: boolean}
): JSX.Element {

	const { route, setRoute, routeSites, setRouteSites } = useGlobalContext();
	const [ showDetailedSite, setShowDetailedSite ] = useState<RouteSiteProps | null>(null);
	const [showMap, setShowMap] = useState<boolean>(false);

	const setHighlightedSite = useCallback((uuid: string | null):void => {
		setHighlightedUUID(uuid);
	}, []);

	const setSiteStatus = useCallback((uuid: string, status: string): void => {
		if (!route || !setRoute || !routeSites || !setRouteSites) return;
		const newRouteSites = routeSites.map((rs: RouteSiteProps) => {
			if (rs.site.uuid === uuid)
				rs.status = status;
			return rs
		})
		setRoute({ ...route, isSaved: false });
		setRouteSites(newRouteSites);
	}, [route, routeSites, setRouteSites]);

	const scrollToTop = useCallback(():void => {
    const element = document.getElementById('route-sites-list');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

	const visibleSites = 
		(route && route.ready) || readOnly
			? (routeSites || []).filter((rs: RouteSiteProps): boolean => rs.status === 'accepted')
			: (routeSites || []).filter((rs: RouteSiteProps): boolean => rs.visible !== false);

	if (visibleSites.length === 0)
		return (
			<div className="mt-3">
				<h5>Ei kohteita</h5>
				Valitettavasti tälle reitille ei löytynyt sopivia kohteita.
			</div>
		);
	return (
		<div id="route-sites-list">
			{showDetailedSite && 
				<SiteDetails 
					routeSite={showDetailedSite}
					onClose={() => setShowDetailedSite(null)}
				/>
			}
			{showMap && 
				<SiteDetails 
					onClose={() => setShowMap(false)}
				/>
			}
			{visibleSites.map((rs: RouteSiteProps, idx: number) => {
				return (
					<div 
						className="mt-1"
						key={rs.site.uuid} 
						onMouseEnter={() => setHighlightedSite(rs.site.uuid)}
						onMouseLeave={() => setHighlightedSite(null)}
					>
						{rs.status === "rejected" &&
							<div className="d-grid gap-2 mt-2">
								<Button variant="outline-danger" onClick={() => setSiteStatus(rs.site.uuid, 'suggested')}>{rs.site.name}</Button>
							</div>
						}
						<Collapse in={rs.status !== "rejected"}>
							<div>
								<SiteCard
									routeSite={rs}
									highlightedUUID={highlightedUUID}
									siteIndex={idx}
									numVisibleSites={visibleSites.length}
									setShowDetailedSite={setShowDetailedSite}
									setSiteStatus={setSiteStatus}
									showButtons={!(route && route.ready) && !readOnly}
								/>
							</div>
						</Collapse>
					</div>
				);
			})}
			<br/>
			{visibleSites && visibleSites.length > 10 &&
				<Button onClick={scrollToTop}>
					Palaa listan alkuun
				</Button>
			}
		</div>
	)
}

export default RouteSites;
