import { IoBag, IoBed, IoBook, IoCamera, IoFastFood, IoLeaf, IoPaw, IoSnow, IoSparkles, IoSunny, IoTicket } from "react-icons/io5";
import { MdChildCare, MdCommute, MdFamilyRestroom, MdPublishedWithChanges, MdWc, MdWheelchairPickup } from "react-icons/md";
import { TbCurrencyEuroOff } from "react-icons/tb";

const SiteAttributes = [	
	{ 
		attribute: "class_recreation",
		condition: true,
		icon: <IoTicket />,
		text: "Ajanviete",
		color: "color-recreation",
		description: "leikkipuistot, uimarannat...",
	},
	{ 
		attribute: "class_experience",
		condition: true,
		icon: <IoSparkles />,
		text: "Elämykset",
		color: "color-experience",
		description: "huvipuistot, kylpylät...",
	},
	{ 
		attribute: "class_culture",
		condition: true,
		icon: <IoBook />,
		text: "Kulttuuri",
		color: "color-culture",
		description: "museot, näyttelyt, teatterit...",
	},
	{ 
		attribute: "class_nature",
		condition: true,
		icon: <IoLeaf />,
		text: "Luonto",
		color: "color-nature",
		description: "kansallispuistot, retkeilyalueet...",
	},
	{
		attribute: "class_accommodation",
		condition: true,
		icon: <IoBed />,
		text: "Majoitus",
		color: "color-accommodation",
	},
	{ 
		attribute: "class_travel",
		condition: true,
		icon: <MdCommute />,
		text: "Matkapalvelut",
		color: "color-travel",
		description: "huoltoasemat, levähdyspaikat...",
	},
	{ 
		attribute: "class_attraction",
		condition: true,
		icon: <IoCamera />,
		text: "Nähtävyys",
		color: "color-attraction",
		description: "rakennukset, muistomerkit...",
	},
	{ 
		attribute: "class_shopping",
		condition: true,
		icon: <IoBag />,
		text: "Ostokset",
		color: "color-shopping",
	},
	{ 
		attribute: "class_nutrition",
		condition: true,
		icon: <IoFastFood />,
		text: "Ruoka ja juoma",
		color: "color-nutrition",
	},
	{
		attribute: "customer",
		condition: "adults",
		icon: <MdWc />,
		text: "Ensisijaisesti aikuisille",
		color: "color-customer",
	},
	{
		attribute: "customer",
		condition: "children",
		icon: <MdChildCare />, 
		text: "Ensisijaisesti lapsille",
		color: "color-customer",
	},
	{
		attribute: "customer",
		condition: "everyone",
		icon: <MdFamilyRestroom />,
		text: "Soveltuu kaikenikäisille",
		color: "color-customer",
	},
	{
		attribute: "season",
		condition: "summer",
		icon: <IoSunny />,
		text: "Kesäkohde",
		color: "color-season",
	},
	{
		attribute: "season",
		condition: "winter",
		icon: <IoSnow />,
		text: "Talvikohde",
		color: "color-season",
	},
	{
		attribute: "season",
		condition: "year",
		icon: <MdPublishedWithChanges />,
		text: "Ympärivuotinen kohde",
		color: "color-season",
	},
	{
		attribute: "accessible",
		condition: true,
		icon: <MdWheelchairPickup />,
		text: "Esteetön / Vierailu avustettuna",
		color: "color-accessible",
	},
	{
		attribute: "petfriendly",
		condition: true,
		icon: <IoPaw />,
		text: "Lemmikit sallittu",
		color: "color-petfriendly",
	},
	{
		attribute: "costless",
		condition: true,
		icon: <TbCurrencyEuroOff />,
		text: "Maksuton",
		color: "color-costless",
	}
];

export default SiteAttributes;

export const siteClassAttributes = SiteAttributes.filter((sa: any) => sa.attribute.startsWith('class'));
