import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncFn } from "react-use";
import { signOut } from "supertokens-auth-react/recipe/passwordless";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner"
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import UserInfo from "../components/UserInfo";
import UserRoutes from "../components/UserRoutes";
import UserSites from "../components/UserSites";
import useGlobalContext from "../common/GlobalContext";

function HelloView(): JSX.Element {
	const [active, setActive] = useState('info');

	const {
		client,
		user,
		setUser,
		setRoute,
		setRouteSites,
		setWaypoints,
	} = useGlobalContext();

	const goto = useNavigate();

	const [loadDataState, loadData] = useAsyncFn(async () => {
		if (setUser) {
			const { data } = await client.get("/user/me/");
			setUser({ email: data.email || "<virhe>" });
		}
	}, [user]);

	useEffect(() => {
		if (
			!user &&
			!loadDataState.loading &&
			!loadDataState.error
		) {

			loadData();
		}
	}, []);

	const onSignOut = useCallback(() => {
		if (setRoute) setRoute(null);
		if (setRouteSites) setRouteSites(null);
		if (setWaypoints) setWaypoints(null);
		if (setUser) setUser(null);
		signOut();
		goto("/");
	}, []);

	if (loadDataState.loading) {
		return <Spinner />;
	}

	return (
		<SessionAuth>
			<Container>
				<Row>
					<Col className="text-center">
						<div>

							<ButtonGroup>
								<Button variant={active === 'info' ? 'info' : 'primary'} onClick={() => setActive('info')}>Käyttäjätiedot</Button>
								<Button variant={active === 'routes' ? 'info' : 'primary'} onClick={() => setActive('routes')}>Omat reitit</Button>
								<Button variant={active === 'sites' ? 'info' : 'primary'} onClick={() => setActive('sites')}>Omat kohteet</Button>
							</ButtonGroup>
							<div style={{ float: "right" }}>
								<Button
									style={{ float: "right" }}
									onClick={onSignOut}
									variant="danger"
								>
									Kirjaudu ulos
								</Button>
							</div>
						</div>
					</Col>
					<Row className="mt-3">
						{active === 'info' &&
							<Col>
								<UserInfo />
							</Col>
						}
						{active === 'routes' &&
							<Col>
								<UserRoutes />
							</Col>
						}
						{active === 'sites' &&
							<Col>
								<UserSites />
							</Col>
						}

					</Row>

				</Row>
				<Row>

				</Row>
			</Container>
		</SessionAuth>
	);
}

export default HelloView;
