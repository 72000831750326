import { useEffect } from "react";
import Leaflet from 'leaflet'
import { CircleMarker, MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from "react-leaflet";
import { AddressProps, RouteSiteProps } from "../common/Types";
import useGlobalContext from "../common/GlobalContext";
import { TILEURL } from '../App';
import L from 'leaflet';

const defaultCenter: [number, number] = [65.50, 25.50];
const corner1 = Leaflet.latLng(58, 18);
const corner2 = Leaflet.latLng(72, 32);
const bounds = Leaflet.latLngBounds(corner1, corner2)


const RecenterAutomatically = ({markers, allowMapRecenter, onUnallowMapRecenter}:{markers: Array<any>, allowMapRecenter: boolean, onUnallowMapRecenter?: () => void}) => {
 const map = useMap();
	useEffect(() => {
		if (allowMapRecenter && markers && markers.length > 0) {
			const group = L.featureGroup(markers);
			map.fitBounds(group.getBounds(), {padding: L.point(10, 10)});
			if (onUnallowMapRecenter) onUnallowMapRecenter();
		}
	}, [markers]);
  return null;
}


function Map({acceptedSitesOnly, highlightedUUID, allowMapRecenter, onUnallowMapRecenter}:{acceptedSitesOnly: boolean, highlightedUUID?: string | null, allowMapRecenter: boolean, onUnallowMapRecenter?: () => void}): JSX.Element {
	const { routeSites, route } = useGlobalContext();

	const visibleSites = acceptedSitesOnly || (route && route.ready)
		? (routeSites || []).filter((rs: RouteSiteProps): boolean => rs.status === 'accepted')
		: (routeSites || []).filter((rs: RouteSiteProps): boolean => rs.visible !== false && rs.status !== "rejected");

	const center = (route && route.center) || defaultCenter;
	const markersL: L.Marker[] = [];
	const markers: any[] = [];

	(route && route.addresses || []).forEach((ra: AddressProps) => {
			markersL.push(L.marker([ra.lat, ra.lon]));
			const addrIcon = L.divIcon({html: 
				`<span class="mapmunicipality">${ra.municipality}</span>`
			})
			markers.push(
				<Marker
					key={ra.municipality}
					position={[ra.lat, ra.lon]}
					icon={addrIcon}
				/>
			);
	});

	return (
		<MapContainer
			center={center}
			zoom={5}
			doubleClickZoom={false}
			scrollWheelZoom={true}
      dragging={true}
			zoomControl={true}
			maxBoundsViscosity={1}
      maxBounds={bounds}
			minZoom={4}
			maxZoom={9}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMapin</a> tekijät'
				url={TILEURL + "/{z}/{x}/{y}.png"}
			/>
			{route && route.coords && <Polyline positions={route.coords} pathOptions={{ color: "blue" }} />}
			{markers.map((m: any) => m)}
			{visibleSites.map((ra: RouteSiteProps) => {
				return (
					<CircleMarker 
							key={ra.site.uuid} 
							center={[ra.site.address.lat, ra.site.address.lon]}
							radius={4 + (ra.status === "accepted" ? 2 : 0)}
							pathOptions={function() {
								if (ra.status === 'accepted') return { color: 'green'};
								if (ra.status === 'rejected') return { color: 'red'};
								return { color: 'blue'};
							}()}
					>
						<Popup>
							{ra.site.name}
						</Popup>

						{highlightedUUID === ra.site.uuid && 
							<Marker
								position={[ra.site.address.lat, ra.site.address.lon]}
							/>
						}
					</CircleMarker>
				);
			})}
			<RecenterAutomatically markers={markersL} allowMapRecenter={allowMapRecenter} onUnallowMapRecenter={onUnallowMapRecenter} />
		</MapContainer>
	)
}

export default Map;
