import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";

function CollapseButton(
		{text, icon, onClick, isOpen, isLoading, disabled, noCaret, variant}
		:{text: string, icon: JSX.Element, onClick: () => void, isOpen: boolean, isLoading?: boolean, disabled?: boolean, noCaret?: boolean, variant?: string}
): JSX.Element {
	return (
		<div className="d-grid gap-2 mt-2">
			<Button onClick={onClick} variant={variant || "primary"} size="lg" disabled={disabled || false}>
				{icon} {text} <span className="collapse-caret"> {!noCaret && (isLoading ? <Spinner size="sm" /> : (!isOpen ? <IoCaretDown /> : <IoCaretUp />))}</span>
			</Button>
		</div>
	);
}

export default CollapseButton;
