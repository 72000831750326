import { useState, useEffect } from "react";
import { useAsyncFn } from "react-use";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Collapse from 'react-bootstrap/Collapse';
import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { IoEarth, IoLocation } from "react-icons/io5";
import { MdTimeline } from "react-icons/md";
import useGlobalContext from '../common/GlobalContext';
import { AddressProps } from "../common/Types";
import { getCenter, getCoords } from "../common/Utils";
import Disclaimer from "../components/Disclaimer";
import CollapseButton from "../components/CollapseButton";
import Map from "../components/Map";
import RouteSites from "../components/RouteSites";
import Waypoints from "../components/Waypoints";

const LOCALSTORAGE_NAFIGAATTORI_DISCLAIMER_SEEN = 'nafigaattori_disclaimer_seen';


function RouteView(): JSX.Element {
	const { client, route, setRoute, routeSites, setRouteSites, waypoints, setWaypoints } = useGlobalContext();
	const { pathUUID } = useParams();
	const goto = useNavigate();

	const [ highlightedUUID, setHighlightedUUID ] = useState<string | null>(null);
	const [ showDisclaimer, setShowDisclaimer ] = useState<boolean>(localStorage.getItem(LOCALSTORAGE_NAFIGAATTORI_DISCLAIMER_SEEN) === 'true' ? false : true);
	const [ destinationsOpen, setDestinationsOpen ] = useState<boolean>(true);
	const [ mapOpen, setMapOpen ] = useState<boolean>(false);
	const [ sitesOpen, setSitesOpen ] = useState<boolean>(route && routeSites !== null ? true : false);
	const [ allowMapRecenter, setAllowMapRecenter ] = useState<boolean>(true);

	const [computeRouteState, computeRoute] = useAsyncFn(async () => {
		const { data } = await async function(): Promise<any> {
				const addresses = (waypoints || []).map((wp: any) => wp.municipality);
				if (route && route.uuid)
					return await client.patch(`/routes/${route.uuid}/`, { addresses });
				else
					return await client.post(`/routes/`, { addresses });
		}();
		const coords = getCoords(data);
		if (setRoute) setRoute({center: getCenter(coords), uuid: data['uuid'], coords, isSaved: false, addresses: data['addresses'], municipalities: data['addresses'].map((a: AddressProps) => a.municipality), public: data['public'], ready: data['ready'], omitted_classes: data['omitted_classes'], min_travelled_distance: data['min_travelled_distance'], max_distance_from_route: data['max_distance_from_route'], readOnly: data['read_only']});
		setAllowMapRecenter(true);
		retrieveSites();
		if (pathUUID !== data['uuid'])
			goto(`/reitti/${data['uuid']}`, {replace: true});
	}, [route, waypoints])

	const [retrieveRouteState, retrieveRoute] = useAsyncFn(async () => {
		if (pathUUID === 'uusi')
			return;
		const { data } =  await client.get(`/routes/${pathUUID}/`);
		const coords = getCoords(data);
		if (setRoute) setRoute({name: data['name'], uuid: data['uuid'], coords, isSaved: true, addresses: data['addresses'], municipalities: data['municipalities'], public: data['public'], ready: data['ready'], omitted_classes: data['omitted_classes'], min_travelled_distance: data['min_travelled_distance'], max_distance_from_route: data['max_distance_from_route'], readOnly: data['read_only']});
		const wp = data['addresses'].map((a: any, i: number) => {return {ordinal: i + 1, municipality: a.municipality}});
		if (setWaypoints) setWaypoints(wp);
		retrieveSites();
		setAllowMapRecenter(true);
	}, [route, pathUUID])

	const [retrieveSitesState, retrieveSites] = useAsyncFn(async (): Promise<void> => {
		if (!route || !route.uuid)
			return;
		const { data } =  await client.get(`/routes/${route.uuid}/sites/`);
		const visibleSites = data.filter((rs: any) => rs.status !== 'hidden');
		if (setRouteSites) setRouteSites(visibleSites);
		setSitesOpen(true);
	}, [route])

	useEffect(() => {
		const hasErrors = retrieveRouteState.error || computeRouteState.error || retrieveSitesState.error ? true : false;
		const isLoading = retrieveRouteState.loading || computeRouteState.loading || retrieveSitesState.loading ? true : false;
		if (routeSites === null && setRoute && sitesOpen) {
			setDestinationsOpen(true);
			setSitesOpen(false);
		}
		if (!hasErrors && !isLoading) {
			if (pathUUID && (!route || route.uuid !== pathUUID) && setRoute) {
				retrieveRoute();
			}
			else if (route && route.uuid && routeSites === null) {
				retrieveSites();
			}
		}
	}, [route, pathUUID]);

	let maxedOut = false;
	try {
		if(computeRouteState.error && (computeRouteState.error as any).response.data['routes'][0] === 'Too many routes')
			maxedOut = true;
	}
	catch {
		maxedOut = false;
	}

	const routeName = pathUUID === "uusi" ? "uusi reitti" : (route && route.uuid && (route.name || "nimetön reitti"));

	return (
		<Container fluid className="route-view">
			{showDisclaimer &&
				<Disclaimer onAccept={() => {localStorage.setItem(LOCALSTORAGE_NAFIGAATTORI_DISCLAIMER_SEEN, 'true'); setShowDisclaimer(false)}} />
			}
			<Modal show={mapOpen} fullscreen={true} onHide={() => setMapOpen(false)}>
				<Modal.Header closeButton style={{ height: "50px" }}>
					<div style={{ width: "90%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
						Reitti: {routeName === 'uusi reitti' ? 'reittiä ei ole asetettu' : routeName}
					</div>
				</Modal.Header>
				<Modal.Body className="text-center">
					<div id="map-div">
						<Map acceptedSitesOnly={false} highlightedUUID={null} allowMapRecenter={allowMapRecenter} onUnallowMapRecenter={() => setAllowMapRecenter(false)} />
					</div>
				</Modal.Body>
			</Modal>
			<Row>
				<Col md="6" lg="7" xl="8" className="pt-1 order-md-2 d-md-block d-none">
					<div id="map-div">
						<Map acceptedSitesOnly={false} highlightedUUID={highlightedUUID} allowMapRecenter={allowMapRecenter} onUnallowMapRecenter={() => setAllowMapRecenter(false)} />
					</div>
				</Col>
				<Col md="6" lg="5" xl="4" className="pt-0 order-md-1">
					<div className="scrollable">
						<CollapseButton text={`Reitti: ${routeName}`} icon={<MdTimeline />} onClick={() => setDestinationsOpen(!destinationsOpen)} isOpen={destinationsOpen} isLoading={retrieveRouteState.loading || computeRouteState.loading} disabled={false}/>
						<Collapse in={destinationsOpen}>
							<div>
								<Waypoints readOnly={route && route.readOnly ? true : false} onCompute={computeRoute} loading={retrieveRouteState.loading || computeRouteState.loading} error={(maxedOut && 'maxed') || (retrieveRouteState.error && 'retrieve') || (computeRouteState.error && 'compute') || null} maxed={false} sitesSelected={routeSites && routeSites.some((rs: any) => rs.status !== 'suggested') ? true : false}/>

								<div className="d-md-none d-sm-block">
									<CollapseButton text="Kartta" variant="info" icon={<IoEarth />} onClick={() => setMapOpen(!mapOpen)} isOpen={mapOpen} isLoading={retrieveRouteState.loading || computeRouteState.loading} disabled={false} noCaret={true}/>
								</div>

							</div>
						</Collapse>

						<CollapseButton text={"Kohteet"} icon={<IoLocation />} onClick={() => setSitesOpen(!sitesOpen)} isOpen={sitesOpen} isLoading={retrieveSitesState.loading} disabled={!routeSites} />
						<Collapse in={sitesOpen}>
							<div>
								{retrieveSitesState.loading
									? <Spinner className="mt-3" />
									: <RouteSites readOnly={route && route.readOnly ? true : false} highlightedUUID={highlightedUUID} setHighlightedUUID={setHighlightedUUID}/>
								}
							</div>
						</Collapse>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default RouteView;
