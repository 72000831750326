import { useState } from "react";
import { useAsyncFn } from "react-use";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import useGlobalContext from "../common/GlobalContext";
import { RouteSiteProps } from "../common/Types";

function RouteSave({ onClose }: { onClose: () => void }): JSX.Element {
  const [error, setError] = useState<boolean>(false);
  const { client, route, user, setRoute, routeSites } = useGlobalContext();

  const [name, setName] = useState<string>((route && route.name) || "");
  const [readyRoute, setReadyRoute] = useState<boolean>(
    (route && route.ready) || false,
  );
  const [publicRoute, setPublicRoute] = useState<boolean>(
    (route && route.public) || false,
  );

  const [state, saveRoute] = useAsyncFn(async () => {
    let success = false;
    if (route && setRoute) {
      const result = await client.patch(`/routes/${route.uuid}/`, {
        name: name,
        public: publicRoute,
        ready: readyRoute,
      });
      if (result.status == 200) {
        const result = await client.post(`/routes/${route.uuid}/sites/`, {
          sites: (routeSites || []).map((rs: RouteSiteProps) => {
            return { uuid: rs.site.uuid, status: rs.status };
          }),
        });
        if (result.status == 200) {
          setRoute({
            ...route,
            name: name,
            ready: readyRoute,
            public: publicRoute,
            isSaved: true,
          });
          success = true;
          onClose();
        }
      }
    }
    if (!success) setError(true);
  }, [route, name, readyRoute, publicRoute]);

  if (!user)
    return (
      <Modal fullscreen="md-down" show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <h1>Tallenna reitti</h1>
        </Modal.Header>
        <Modal.Body>
          <h3>Kirjaudu sisään</h3>
          <p>
            Jos haluat tallentaa reitin, on sinun ensin{" "}
            {
              <Link onClick={onClose} to="/omat_reitit">
                kirjauduttava sisään
              </Link>
            }
            .
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={onClose}>
            Peruuta
          </Button>
        </Modal.Footer>
      </Modal>
    );

  return (
    <Modal fullscreen="md-down" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <h1>Tallenna reitti</h1>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reitin nimi</Form.Label>
          <Form.Control
            type="text"
            placeholder="Syötä reitin nimi"
            value={name}
            onChange={(e: React.BaseSyntheticEvent) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Check
            type="checkbox"
            label="Reitti valmis"
            checked={readyRoute}
            onChange={(e: React.BaseSyntheticEvent) =>
              setReadyRoute(e.target.checked)
            }
          />
        </Form.Group>
        <p>
          Kun reitti merkitään valmiiksi, näytetään kohdelistauksessa vain
          reitille hyväksytyt kohteet. Muut kohteet piilotetaan.
        </p>
        <Form.Group className="mt-3">
          <Form.Check
            type="checkbox"
            label="Julkinen reitti"
            checked={publicRoute}
            onChange={(e: React.BaseSyntheticEvent) =>
              setPublicRoute(e.target.checked)
            }
          />
        </Form.Group>
        <p>
          Kun reitti on julkinen, muilla käyttäjillä on mahdollisuus tarkastella
          sitä. He eivät kuitenkaan pysty tekemään muutoksia.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={onClose}>
          Peruuta
        </Button>
        <Button
          variant="success"
          disabled={!name || state.loading}
          onClick={saveRoute}
        >
          {state.loading ? <Spinner /> : "Tallenna"}
        </Button>
        {(state.error || error) && (
          <p style={{ color: "red" }}>Reitin tallennus epäonnistui.</p>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default RouteSave;
