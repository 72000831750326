import { useState } from "react";
import { useAsyncFn } from "react-use";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import useGlobalContext from "../common/GlobalContext";
import { siteClassAttributes } from "../common/SiteAttributes";
import { SiteProps } from "../common/Types";

const CurrentValue = ({ exists, value }: { exists: boolean; value: any }) =>
  exists ? (
    <div className="site-suggestion-existing-info">
      <div className="site-suggestion-input-text-current">Nykyinen tieto:</div>
      <div style={{ marginLeft: "130px", overflowWrap: "anywhere" }}>
        {value}
      </div>
    </div>
  ) : (
    <></>
  );
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

function getCaptcha(): { first: number; second: number; answer: string } {
  let first = 0;
  let second = 0;
  let sum = 0;

  while (sum < 2 || sum > 9) {
    first = getRandomInt(1, 9);
    second = getRandomInt(1, 10 - first);
    sum = first + second;
  }
  return { first, second, answer: "" };
}

function SiteSuggestion({
  site,
  isHometown,
  onCancel,
}: {
  site: SiteProps;
  isHometown: boolean;
  onCancel: () => void;
}): JSX.Element {
  const { client } = useGlobalContext();
  const [suggestion, setSuggestion] = useState({
    ...site,
    site: site.uuid,
    uuid: "",
    suggested_by: "",
    keywords: site.keywords ? site.keywords.join(",") : "",
    address: {
      ...site.address,
      municipality:
        site.address.village && site.address.municipality
          ? `${site.address.village} / ${site.address.municipality}`
          : site.address.village || site.address.municipality,
    },
  });
  const [image1, setImage1] = useState<File | null>(null);
  const [image2, setImage2] = useState<File | null>(null);
  const [image3, setImage3] = useState<File | null>(null);
  const [imageOnly, setImageOnly] = useState<boolean | null>(
    site.uuid ? null : false,
  );
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [captcha, setCaptcha] = useState(getCaptcha());
  const [suggestionSent, setSuggestionSent] = useState("");
  const [suggestionError, setSuggestionError] = useState("");
  const [email, setEmail] = useState("");
  const [allowContact, setAllowContact] = useState(false);

  const [uploadDataState, uploadData] = useAsyncFn(async () => {
    try {
      const formData = new FormData();

      formData.append("captcha", JSON.stringify(captcha));
      formData.append("site", suggestion.site);
      formData.append(
        "name",
        `${isHometown ? "Kotiseutuehdotus - " : ""}${suggestion.name}`,
      );
      formData.append("address_street", suggestion.address.street || "");
      formData.append(
        "address_street_num",
        suggestion.address.street_num || "",
      );
      formData.append(
        "address_postalcode",
        suggestion.address.postalcode || "",
      );
      formData.append(
        "address_municipality",
        suggestion.address.municipality || suggestion.name,
      );
      formData.append("description", suggestion.description || "");
      formData.append("url", suggestion.urls ? suggestion.urls[0] : "");
      formData.append("season", suggestion.season || "");
      formData.append("customer", suggestion.customer || "");
      formData.append("keywords", suggestion.keywords || "");
      formData.append("terms_accepted", termsAccepted ? "true" : "false");
      formData.append("petfriendly", suggestion.petfriendly ? "true" : "false");
      formData.append("accessible", suggestion.accessible ? "true" : "false");
      formData.append("costless", suggestion.costless ? "true" : "false");
      formData.append("suggested_by", email);
      formData.append("allow_contact", allowContact ? "true" : "false");
      formData.append("image_only", imageOnly ? "true" : "false");

      siteClassAttributes.forEach((sa: { attribute: string }) => {
        const value = (suggestion as unknown as Record<string, string>)[
          sa.attribute as keyof SiteProps
        ];
        formData.append(sa.attribute as string, value || "false");
      });

      if (image1) formData.append("image1", image1);
      if (image2) formData.append("image2", image2);
      if (image3) formData.append("image3", image3);

      const response = await client.post("/suggestions/", formData);
      if ([200, 201].includes(response.status)) setSuggestionSent("OK");
      else setSuggestionError(`Tuntematon virhe: koodi ${response.status}`);
    } catch (error: any) {
      const data = error.response.data;
      let msg = "Virheet: ";
      if (data.captcha) msg += "Virheellinen tarkistuskysymyksen vastaus. ";
      if (data.address_municipality) msg += "Virheellinen kunta. ";
      if (data.description) msg += "Virheellinen kuvausteksti. ";
      if (data.terms_accepted) msg += "Ehtoja ei hyväksytty. ";
      if (data.image1 || data.image2 || data.image3)
        msg += "Epäyhteensopiva kuvatiedosto. ";
      if (msg === "Virheet: ") msg = "Tuntematon virhe.";
      setSuggestionError(msg);
    }
  }, [
    suggestion,
    image1,
    image2,
    image3,
    captcha,
    email,
    allowContact,
    termsAccepted,
  ]);

  const handleImageChange = (
    imageId: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0]) {
      if (imageId === 1) setImage1(event.target.files[0]); // update the image state when a file is selected
      if (imageId === 2) setImage2(event.target.files[0]); // update the image state when a file is selected
      if (imageId === 3) setImage3(event.target.files[0]); // update the image state when a file is selected
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    uploadData();
  };

  const exists = site.uuid ? true : false;
  const classSelected =
    suggestion.class_accommodation ||
    suggestion.class_attraction ||
    suggestion.class_culture ||
    suggestion.class_nature ||
    suggestion.class_nutrition ||
    suggestion.class_recreation ||
    suggestion.class_shopping;

  const isModified = suggestion.modified || image1 || image2 || image3;
  return (
    <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
      <Modal
        show={imageOnly === null}
        onHide={() => {
          return null;
        }}
      >
        <Modal.Header>
          <h3>Haluatko lisätä kuvan vai muokata tietoja?</h3>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Button className="m-5" onClick={() => setImageOnly(true)}>
            Lisään kuvan
          </Button>
          <Button className="m-5" onClick={() => setImageOnly(false)}>
            Muokkaan tietoja
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={suggestionSent ? true : false} onHide={onCancel}>
        <Modal.Header closeButton>
          <h3 style={{ color: "green" }}>Lähetys onnistui</h3>
        </Modal.Header>
        <Modal.Body>
          <p style={{ margin: "20px" }}>
            Kiitokset lähettämästäsi ehdotuksesta!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancel}>OK</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={suggestionError ? true : false}
        onHide={() => setSuggestionError("")}
      >
        <Modal.Header closeButton>
          <h3 style={{ color: "red" }}>Lähetys epäonnistui</h3>
        </Modal.Header>
        <Modal.Body>
          <p>{suggestionError}</p>
          <p style={{ margin: "20px" }}>Tarkista tiedot ja yritä uudestaan.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setSuggestionError("")}>OK</Button>
        </Modal.Footer>
      </Modal>

      <Row>
        <Col>
          {imageOnly ? (
            <h2 style={{ float: "left" }}>Lisää kuva</h2>
          ) : (
            <h2 style={{ float: "left" }}>
              Ehdota{" "}
              {isHometown ? "kotiseutua" : exists ? "muutoksia" : "kohdetta"}{" "}
            </h2>
          )}
          <div style={{ float: "right" }}>
            <Button onClick={onCancel} variant="danger">
              Peruuta
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Form onSubmit={handleSubmit}>
          {imageOnly === true && <h3>{suggestion.name}</h3>}
          {imageOnly !== true && (
            <>
              <Form.Group>
                <Form.Label className="col-form-label-lg">
                  {isHometown
                    ? "Kotiseutu (kunta, maakunta, kylä)"
                    : "Kohteen nimi (vaaditaan)"}
                </Form.Label>
                <Form.Control
                  value={suggestion.name}
                  onChange={(e: React.BaseSyntheticEvent) =>
                    setSuggestion({
                      ...suggestion,
                      modified: true,
                      name: e.currentTarget.value,
                    })
                  }
                />
                <CurrentValue exists={exists} value={site.name} />
              </Form.Group>

              {!isHometown && (
                <Form.Group>
                  <Form.Label className="mt-5 col-form-label-lg">
                    Osoite (kunta vaaditaan)
                  </Form.Label>
                  <p>
                    Kunnan tilalla voi tarvittaessa käyttää myös tarkempaa
                    tietoa (esimerkiksi mieluummin <i>Kilpisjärvi</i> kuin{" "}
                    <i>Enontekiö</i>).
                  </p>
                  <InputGroup>
                    <InputGroup.Text className="site-suggestion-input-text">
                      Katu
                    </InputGroup.Text>
                    <Form.Control
                      value={suggestion.address.street || ""}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          address: {
                            ...suggestion.address,
                            street: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mt-1">
                    <InputGroup.Text className="site-suggestion-input-text">
                      Katunumero
                    </InputGroup.Text>
                    <Form.Control
                      value={suggestion.address.street_num || ""}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          address: {
                            ...suggestion.address,
                            street_num: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mt-1">
                    <InputGroup.Text className="site-suggestion-input-text">
                      Postinumero
                    </InputGroup.Text>
                    <Form.Control
                      value={suggestion.address.postalcode || ""}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          address: {
                            ...suggestion.address,
                            postalcode: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mt-1">
                    <InputGroup.Text className="site-suggestion-input-text">
                      Kunta
                    </InputGroup.Text>
                    <Form.Control
                      value={suggestion.address.municipality || ""}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          address: {
                            ...suggestion.address,
                            municipality: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </InputGroup>
                  <CurrentValue
                    exists={exists}
                    value={`${site.address.street || ""} ${
                      site.address.street_num || ""
                    } ${site.address.postalcode || ""} ${
                      site.address.municipality
                    }`}
                  />
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label className="mt-3 col-form-label-lg">
                  Kuvaus {!isHometown && "(vaaditaan)"}
                </Form.Label>
                <Form.Control
                  placeholder={
                    isHometown
                      ? "Kerro, minkä vuoksi matkailijan kannattaisi vierailla juuri täällä."
                      : "Ytimekäs ja selkeä kuvaus siitä, minkälaisesta kohteesta on kyse."
                  }
                  style={{ height: "300px" }}
                  as="textarea"
                  value={suggestion.description}
                  onChange={(e: React.BaseSyntheticEvent) =>
                    setSuggestion({
                      ...suggestion,
                      modified: true,
                      description: e.currentTarget.value,
                    })
                  }
                />
              </Form.Group>

              {!isHometown && (
                <>
                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Luokat (vaaditaan)
                    </Form.Label>
                    <p>
                      Valitse vain kohteen <strong>pääasialliset</strong> luokat
                      (suositus 1-3 kpl).
                    </p>

                    {siteClassAttributes.map(
                      (sa: {
                        attribute: string;
                        icon: JSX.Element;
                        text: string;
                        description?: string;
                      }): JSX.Element => {
                        return (
                          <Form.Check
                            key={sa.text}
                            label={
                              sa.text +
                              (sa.description ? ` (${sa.description})` : "")
                            }
                            checked={
                              suggestion[sa.attribute as keyof SiteProps]
                                ? true
                                : false
                            }
                            value={sa.attribute || ""}
                            onChange={(e: React.BaseSyntheticEvent) => {
                              (
                                suggestion as unknown as Record<string, boolean>
                              )[sa.attribute as keyof SiteProps] =
                                e.target.checked;
                              setSuggestion({ ...suggestion, modified: true });
                            }}
                          />
                        );
                      },
                    )}
                    <CurrentValue
                      exists={exists}
                      value={siteClassAttributes
                        .map((sa: { attribute: string; text: string }) => {
                          return site[sa.attribute as keyof SiteProps]
                            ? sa.text
                            : "";
                        })
                        .filter((val: string) => val !== "")
                        .join(", ")}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Esteetön / vierailu avustettuna
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={suggestion.accessible === true}
                        value={undefined}
                        type="checkbox"
                        label="Esteetön"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            accessible: e.currentTarget.checked,
                          })
                        }
                      />
                    </div>
                    <CurrentValue
                      exists={exists}
                      value={
                        site.accessible
                          ? "Esteetön"
                          : "Ei merkitty esteettömäksi"
                      }
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Lemmikit sallittu
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={suggestion.petfriendly === true}
                        value={undefined}
                        type="checkbox"
                        label="Lemmikit sallittu"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            petfriendly: e.currentTarget.checked,
                          })
                        }
                      />
                    </div>
                    <CurrentValue
                      exists={exists}
                      value={
                        site.petfriendly
                          ? "Lemmikit sallittu"
                          : "Lemmikkejä ei merkitty sallituiksi"
                      }
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Maksuton
                    </Form.Label>
                    <p>
                      Myös <strong>pääosin</strong> maksuton kohde voidaan
                      merkitä maksuttomaksi, vaikka kohteessa olisi lisäksi myös
                      maksullisia palveluita.
                    </p>
                    <div>
                      <Form.Check
                        checked={suggestion.costless === true}
                        value={undefined}
                        type="checkbox"
                        label="Maksuton"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            costless: e.currentTarget.checked,
                          })
                        }
                      />
                    </div>
                    <CurrentValue
                      exists={exists}
                      value={
                        site.costless ? "Maksuton" : "Ei merkitty maksuttomaksi"
                      }
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Ensisijainen kohderyhmä
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={
                          !suggestion.customer ||
                          suggestion.customer === "everyone"
                        }
                        type="radio"
                        label="Kaikenikäiset"
                        value="everyone"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            customer: e.currentTarget.value,
                          })
                        }
                      />
                      <Form.Check
                        checked={suggestion.customer === "adults"}
                        type="radio"
                        label="Aikuiset"
                        value="adults"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            customer: e.currentTarget.value,
                          })
                        }
                      />
                      <Form.Check
                        checked={suggestion.customer === "children"}
                        type="radio"
                        label="Lapsiperheet"
                        value="children"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            customer: e.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <CurrentValue
                      exists={exists}
                      value={(function () {
                        switch (site.customer) {
                          case "everyone":
                            return "Kaikenikäiset";
                          case "adults":
                            return "Aikuiset";
                          case "children":
                            return "Lapsiperheet";
                        }
                      })()}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Kausi
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={suggestion.season === "year"}
                        type="radio"
                        label="Ympärivuotinen"
                        value="year"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            season: e.currentTarget.value,
                          })
                        }
                      />
                      <Form.Check
                        checked={suggestion.season === "summer"}
                        type="radio"
                        label="Kesä"
                        value="summer"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            season: e.currentTarget.value,
                          })
                        }
                      />
                      <Form.Check
                        checked={suggestion.season === "winter"}
                        type="radio"
                        label="Talvi"
                        value="winter"
                        onChange={(e: React.BaseSyntheticEvent) =>
                          setSuggestion({
                            ...suggestion,
                            modified: true,
                            season: e.currentTarget.value,
                          })
                        }
                      />
                    </div>
                    <CurrentValue
                      exists={exists}
                      value={(function () {
                        switch (site.season) {
                          case "year":
                            return "Ympärivuotinen";
                          case "summer":
                            return "Kesä";
                          case "winter":
                            return "Talvi";
                        }
                      })()}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      WWW-osoite (vapaaehtoinen)
                    </Form.Label>
                    <Form.Control
                      placeholder="www.esimerkki.fi"
                      value={suggestion.urls ? suggestion.urls[0] : ""}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          urls: [e.currentTarget.value],
                        })
                      }
                    />
                    <CurrentValue
                      exists={exists}
                      value={site.urls ? site.urls[0] : ""}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mt-5 col-form-label-lg">
                      Avainsanat (vapaaehtoinen)
                    </Form.Label>
                    <Form.Control
                      placeholder="Esimerkiksi: kalastus,retkeily,uimaranta"
                      value={suggestion.keywords}
                      onChange={(e: React.BaseSyntheticEvent) =>
                        setSuggestion({
                          ...suggestion,
                          modified: true,
                          keywords: e.currentTarget.value,
                        })
                      }
                    />
                    <CurrentValue
                      exists={exists}
                      value={
                        (site.keywords && site.keywords.join(",")) ||
                        "Ei avainsanoja"
                      }
                    />
                  </Form.Group>
                </>
              )}
            </>
          )}

          <Form.Group>
            <Form.Label className="mt-5 col-form-label-lg">
              Kuvat{" "}
              {imageOnly == true
                ? "(vähintään 1)"
                : "(vapaaehtoinen, vähintään 1 kuva suositeltu)"}
            </Form.Label>
            <Form.Control
              className="mt-1"
              id="image1"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleImageChange(1, e)
              }
            />
            <Form.Control
              className="mt-1"
              id="image2"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleImageChange(2, e)
              }
            />
            <Form.Control
              className="mt-1"
              id="image3"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleImageChange(3, e)
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-5 col-form-label-lg">
              Ehdot (hyväksyntä vaaditaan)
            </Form.Label>
            <p>
              Myönnän Nafigaattorille oikeuden käyttää tällä lomakkeella
              ilmoitettuja tietoja ja/tai kuvaa. Tiedot ja kuvat ovat kaikkien
              Nafigaattorin käyttäjien nähtävissä. Kuvan sekä kuvaustekstin
              tekijänoikeudet kuuluvat itselleni.{" "}
              <strong>En ole kopioinut kuvaa tai tekstiä luvattomasti.</strong>
            </p>
            <Form.Check
              checked={termsAccepted}
              label="Hyväksyn ehdot"
              value={undefined}
              onChange={(e: React.BaseSyntheticEvent) =>
                setTermsAccepted(e.currentTarget.checked)
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-5 col-form-label-lg">
              Tarkistuskysymys (vaaditaan)
            </Form.Label>
            <p>
              Mitä on {captcha.first} + {captcha.second}? Kirjoita vastaus{" "}
              <strong>sanana</strong>, esimerkiksi: 1 + 0 = yksi
            </p>
            <InputGroup>
              <InputGroup.Text className="site-suggestion-input-text">
                {captcha.first} + {captcha.second}
              </InputGroup.Text>
              <Form.Control
                placeholder="Tulos sanana: esim. yksi"
                value={captcha.answer}
                onChange={(e: React.BaseSyntheticEvent) =>
                  setCaptcha({ ...captcha, answer: e.currentTarget.value })
                }
              />
            </InputGroup>
          </Form.Group>

          <Form.Group>
            <Form.Label className="mt-5 col-form-label-lg">
              Yhteystiedot (vapaaehtoinen)
            </Form.Label>
            <p>
              <Link to="/tietosuoja" target="_blank" rel="noopener noreferrer">
                Tietosuojaseloste.
              </Link>
            </p>
            <InputGroup>
              <InputGroup.Text>Email</InputGroup.Text>
              <Form.Control
                type="email"
                value={email}
                onChange={(e: React.BaseSyntheticEvent) =>
                  setEmail(e.currentTarget.value)
                }
              />
            </InputGroup>
            <Form.Check
              checked={allowContact}
              label="Minulle saa lähettää tietoa uusista ominaisuuksista."
              value={undefined}
              onChange={(e: React.BaseSyntheticEvent) =>
                setAllowContact(e.currentTarget.checked)
              }
            />
          </Form.Group>

          <p className="mt-5">
            Ylläpito tarkistaa ilmoitetut tiedot ennen Nafigaattoriin
            lisäämistä.
          </p>
          <Button
            className="mt-1 mb-5"
            disabled={
              isHometown
                ? uploadDataState.loading ||
                  !captcha.answer ||
                  !termsAccepted ||
                  !isModified ||
                  !suggestion.name ||
                  !suggestion.description
                : uploadDataState.loading ||
                  !classSelected ||
                  !captcha.answer ||
                  !termsAccepted ||
                  !isModified ||
                  !suggestion.address.municipality ||
                  !suggestion.description
            }
            type="submit"
          >
            {uploadDataState.loading ? (
              <Spinner />
            ) : imageOnly ? (
              "Lähetä kuva"
            ) : (
              "Lähetä ehdotus"
            )}
          </Button>
        </Form>
      </Row>
    </Col>
  );
}

export default SiteSuggestion;

// import React, { useState } from 'react';

// import axios from 'axios';

// const uploadData = async (name: string, image: File) => {
//   try {
//     const formData = new FormData(); // create a form data object
//     formData.append('name', name); // append the name as a string
//     formData.append('image', image); // append the image as a binary blob
//     const response = await axios.post('/upload', formData); // send a POST request to the local endpoint "/upload" with the form data
//     console.log(response.data); // log the response data
//   } catch (error) {
//     console.error(error); // handle any errors
//   }
// };

// interface Props {
//   uploadData: (name: string, image: File) => void; // a function to upload the data to the server
// }

// const UploadComponent: React.FC<Props> = ({ uploadData }) => {
//   const [name, setName] = useState(''); // a state variable to store the name
//   const [image, setImage] = useState<File | null>(null); // a state variable to store the image

//   const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setName(event.target.value); // update the name state when the input changes
//   };

//   const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files[0]) {
//       setImage(event.target.files[0]); // update the image state when a file is selected
//     }
//   };

//   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault(); // prevent the default form submission behavior
//     if (name && image) {
//       uploadData(name, image); // call the uploadData function with the name and image
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <label htmlFor="name">Name:</label>
//       <input
//         id="name"
//         type="text"
//         value={name}
//         onChange={handleNameChange}
//         required
//       />
//       <label htmlFor="image">Image:</label>
//       <input id="image" type="file" onChange={handleImageChange} required />
//       <button type="submit">Upload</button>
//     </form>
//   );
// };
