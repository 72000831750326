import Client from "./Client";
import { LatLngExpression } from 'leaflet';

export type Coordinate = {
	lat: number;
	lon: number;
};

export type JSONValue =
	| string
	| number
	| boolean
	| JSONObject

export interface JSONObject {
	[x: string]: JSONValue;
}

export type Waypoint = {
	key?: string;
	uuid?: string;
	ordinal: number;
	municipality?: string;
}

export type WaypointsContainer = Array<Waypoint>;

export const EmptyWaypoint = {
	uuid: "",
	ordinal: -1,
	city: "",
}

export type RouteCoordsContainer = LatLngExpression[] | LatLngExpression[][];

export interface AddressProps {
	full?: string | null;
	municipality: string;
	village?: string;
	lat: number;
	lon: number;
	street?: string;
	street_num?: string;
	postalcode?: string;
}

export interface SiteProps {
	uuid: string;
	name: string;
	address: AddressProps;
	description?: string;
	images?: Array<string>;
	thumbnail?: string;
	urls?: Array<string>;
	url_titles?: Array<string>;

	accessible?: boolean;
	petfriendly?: boolean;
	popularity?: string;
	costless?: boolean;
	keywords?: Array<string>;

	class_accommodation?: boolean;
	class_attraction?: boolean;
	class_culture?: boolean;
	class_experience?: boolean,
	class_nature?: boolean;
	class_nutrition?: boolean;
	class_recreation?: boolean;
	class_shopping?: boolean;
	class_travel?: boolean,

	duration_minutes?: boolean;
	duration_hours?: boolean;
	duration_days?: boolean;

	season?: string;
	customer?: string;

	accepted?: boolean;
	published?: boolean;

	modified?: boolean;
}

export interface RouteSiteProps {
	site: SiteProps;
	status: string;
	travelled_distance: number;
	distance_from_route: number;
	visible?: boolean;
}

export interface RouteProps {
	uuid?: string | null;
	name?: string;
	municipalities?: Array<string>;
	coords?: RouteCoordsContainer | null;
	isSaved?: boolean;
	hiddenSites?: Array<string>;
	addresses?: Array<AddressProps>;
	center?: [number, number];
	ready?: boolean;
	public?: boolean;
	omitted_classes?: Array<string>;
	max_distance_from_route?: number | null;
	min_travelled_distance?: number;
	readOnly?: boolean;
}

export interface UserProps {
	username?: string;
	password?: string;
	email?: string;
	exp?: any,
	numRoutes?: number,
}

export interface GlobalContextProps {
	client: Client;
	route?: RouteProps | null;
	setRoute?: (route: RouteProps | null) => void;
	routeSites?: RouteSiteProps[] | null;
	setRouteSites?: (site: RouteSiteProps[] | null) => void;
	user?: UserProps | null;
	setUser?: (user: UserProps | null) => void;
	waypoints?: WaypointsContainer | null;
	setWaypoints?: (waypoints: WaypointsContainer | null) => void;
	shouldReloadSites?: boolean;
	setShouldReloadSites?: (status: boolean) => void;
}

