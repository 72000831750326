import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { IoHome, IoInformation, IoPerson } from "react-icons/io5";
import { MdTimeline } from "react-icons/md";
import useGlobalContext from "../common/GlobalContext";

function TopNav() {
	const { route, user } = useGlobalContext();

	return (
		<Navbar
			className="fixed-top nav-vh"
			bg="primary"
			variant="dark"
			expand="sm"
		>
			<Container>
				<Navbar.Brand>
					Nafigaattori.fi <span style={{ color: "orange" }}>(alpha)</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link as={Link} to="/">
							<IoHome /> Etusivu
						</Nav.Link>
						<Nav.Link
							as={Link}
							to={
								route && route.uuid ? `/reitti/${route.uuid}` : "/reitti/uusi"
							}
						>
							<MdTimeline /> Reitti
						</Nav.Link>
						<Nav.Link as={Link} to="/info">
							<IoInformation /> Info
						</Nav.Link>
						<Nav.Link as={Link} to="/omat_sivut">
							<IoPerson />{" "}
							{user && user.email ? "Omat sivut" : "Kirjaudu sisään"}
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default TopNav;
