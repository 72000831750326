import { useCallback, useState } from "react";
import { useAsyncFn } from "react-use";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import useGlobalContext from "../common/GlobalContext";
import { siteClassAttributes } from "../common/SiteAttributes";

function RouteSettings({ onClose }: { onClose: () => void }): JSX.Element {
	const { client, route, setRoute, setRouteSites } = useGlobalContext();
	const [ settingsDirty, setSettingsDirty ] = useState<boolean>(false);

	const setSiteDistance = useCallback((value: number | null) => {
		setSettingsDirty(true);
		if (setRoute) setRoute({ ...route, max_distance_from_route: value})
	}, [route])

	const setMinTravelledDistance = useCallback((value: number) => {
		setSettingsDirty(true);
		if (setRoute) setRoute({ ...route, min_travelled_distance: value})
	}, [route])

	const setOmitted = useCallback((value: string, isOmitted: boolean) => {
		setSettingsDirty(true);
		const omitted = route && route.omitted_classes || [];
		if (setRoute) {
			if (isOmitted)
				setRoute({ ...route, omitted_classes: omitted.concat([value]) });
			else 
				setRoute({ ...route, omitted_classes: omitted.filter((o: string) => o !== value) });
		}
	}, [route])

	const [state, onSave] = useAsyncFn(async () => {
		if (settingsDirty && route && route.uuid) {
			const response = await async function(): Promise<any> {
				return await client.patch(`/routes/${route.uuid}/`, { omitted_classes: route.omitted_classes || [], max_distance_from_route: route.max_distance_from_route, min_travelled_distance: route.min_travelled_distance });
			}();
			if (response.status === 200) {
				if (setRouteSites) {
					setRouteSites(null);
				}
				if (setRoute)
					setRoute({ ...route });
			}
		}
		onClose();
	}, [route])

	if (!route)
		return <></>;

	return (
		<Modal fullscreen="md-down" scrollable={true} show={true} onHide={onClose}>
			<Modal.Body>
				<h1>Asetukset</h1>
				<h3>Vähimmäismatka</h3>
					<p style={{ textAlign: "justify" }}>Haluatko nähdä kaikki kohteet reitin lähtöpaikasta alkaen? Kilometrilukema on se matka reitin alussa, jonka varrelta ei näytetä kohteita.</p>
					<div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
						<Button className="m-1" onClick={() => setMinTravelledDistance(0)} variant={route.min_travelled_distance === 0 ? "success" : "secondary"}>0 km (kaikki kohteet)</Button>
						<br/>
						<Button className="m-1" onClick={() => setMinTravelledDistance(50)} variant={route.min_travelled_distance === 50 ? "success" : "secondary"}>50 km</Button>
						<Button className="m-1" onClick={() => setMinTravelledDistance(100)} variant={route.min_travelled_distance === 100 ? "success" : "secondary"}>100 km</Button>
						<Button className="m-1" onClick={() => setMinTravelledDistance(150)} variant={route.min_travelled_distance === 150 ? "success" : "secondary"}>150 km</Button>
						<Button className="m-1" onClick={() => setMinTravelledDistance(200)} variant={route.min_travelled_distance === 200 ? "success" : "secondary"}>200 km</Button>
					</div>
				<hr/>
				<h3>Kohteiden etäisyys reitiltä</h3>
					<p style={{ textAlign: "justify" }}>Haluatko nähdä kaikki kohteet vai rajoittaa kohteiden etäisyyttä? Kilometrilukema on kohteen suurin sallittu etäisyys reitiltä (linnuntietä).</p>
					<div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
						<Button className="m-1" onClick={() => setSiteDistance(null)} variant={route.max_distance_from_route === null ? "success" : "secondary"}>50 km (kaikki kohteet)</Button>
						<br/>
						<Button className="m-1" onClick={() => setSiteDistance(40)} variant={route.max_distance_from_route === 40 ? "success" : "secondary"}>40 km</Button>
						<Button className="m-1" onClick={() => setSiteDistance(30)} variant={route.max_distance_from_route === 30 ? "success" : "secondary"}>30 km</Button>
						<Button className="m-1" onClick={() => setSiteDistance(20)} variant={route.max_distance_from_route === 20 ? "success" : "secondary"}>20 km</Button>
						<Button className="m-1" onClick={() => setSiteDistance(10)} variant={route.max_distance_from_route === 10 ? "success" : "secondary"}>10 km</Button>
					</div>
				<hr/>
				<h3>Kiinnostavat luokat</h3>	
				<p>Valitse luokat, joihin lukeutuvia kohteita haluat tarkastella.</p>
				<Container className="p-3 mt-2 mb-2">
					<Row>
						<div style={{ width: "350px", margin: "0 auto" }}>
							{siteClassAttributes.map((sa: {attribute: string, icon: JSX.Element, text: string}, idx: number): JSX.Element => {
									const isHidden = (route.omitted_classes || []).includes(sa.attribute);

									return (
										<div key={idx} className="settings-class-div">
											<input checked={!isHidden} onChange={() => {setOmitted(sa.attribute, !isHidden)}} className="setting-class-checkbox" type="checkbox" value="" />
											<label className="settings-class-label">
												{sa.icon} {sa.text}
											</label>
										</div>
									);
								}
							)}
						</div>
					</Row>
					{state.error && <p style={{ color: 'red' }}>Asetusten tallennus epäonnistui. Tarkasta omien reittiesi määrä.</p>}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="success" onClick={onSave}>{state.loading ? <Spinner /> : "OK"}</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default RouteSettings;
