import { useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { MdCheck, MdEdit, MdOutlineRemoveRedEye } from "react-icons/md"
import SiteDetails from "./SiteDetails";
import SiteEditor from "./SiteEditor";
import { SiteProps } from "../common/Types";
import useGlobalContext from "../common/GlobalContext";

function UserSites(): JSX.Element {
	const [showEditorFor, setShowEditorFor] = useState<string | null>(null);
	const [showDetailsFor, setShowDetailsFor] = useState<string | null>(null);
	const [sites, setSites] = useState<Array<SiteProps> | null>(null);

	const { client } = useGlobalContext();

	const [loadDataState, loadData] = useAsyncFn(async () => {
		const { data } = await client.get("/sites/my/");
		setSites(data);
	}, [sites, setSites]);

	useEffect(() => {
		if (
			!sites &&
			!loadDataState.loading &&
			!loadDataState.error
		) {
			loadData();
		}
	}, []);

	if (loadDataState.loading) {
		return (
			<Container>
				<Spinner />
			</Container>
		)
	}

	return (
		<Container>
			{showEditorFor !== null && <SiteEditor uuid={showEditorFor} onReload={loadData} onClose={() => setShowEditorFor(null)} />}
			{showDetailsFor !== null && <SiteDetails uuid={showDetailsFor} onClose={() => setShowDetailsFor(null)} />}
			<Row className="mt-3">
				<h1>Omat kohteet</h1>
				<p>
					<span style={{ color: "red" }}>Huom!</span> Voit lisätä kohteita vapaasti. Ylläpito tarkastaa lisätyn kohteen, ja hyväksynnän jälkeen kohde on kaikkien käyttäjien nähtävissä. Ongelmatapauksissa ota yhteyttä osoitteeseen nafigaattori@gmail.com.
				</p>

				<p>
					<strong>Suosittelemme kuvan lähettämistä kohteelle.</strong> Toistaiseksi kuvat on lähetettävä sähköpostitse. Lähetä kuvat (1-3 kpl) osoitteeseen nafigaattori@gmail.com.
				</p>

				{sites && sites.length === 0
					? <h3>Sinulla ei vielä ole omia kohteita</h3>
					:
					<Table>
						<thead>
							<tr>
								<td><strong>Nimi</strong></td>
								<td><strong>Sijainti</strong></td>
								<td><strong>Hyväksytty</strong></td>
								<td><strong>Julkaistu</strong></td>
								<td></td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{sites && sites.map((site: SiteProps) =>
								<tr key={site.uuid}>
									<td>
										{site.name}
									</td>
									<td>
										{site.address.full || site.address.municipality || '?'}
									</td>
									<td>
										{site.accepted && <MdCheck />}
									</td>
									<td>
										{site.published && <MdCheck />}
									</td>
									<td>
										<Button onClick={() => setShowEditorFor(site.uuid)}><MdEdit /></Button>
									</td>
									<td>
										<Button onClick={() => setShowDetailsFor(site.uuid)}><MdOutlineRemoveRedEye /></Button>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				}
			</Row>
			<Row>
				<div>
					<Button className="m-3" size="lg" onClick={() => setShowEditorFor('')}>Lisää kohde</Button>
				</div>
			</Row>
		</Container >
	);
}

export default UserSites;
