import { useState } from "react";
import { Link } from "react-router-dom";
import { useAsyncFn } from "react-use";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { MdTimeline, MdAltRoute } from "react-icons/md";
import { RouteSiteProps, SiteProps } from "../common/Types";
import useGlobalContext from "../common/GlobalContext";
import SiteIcons from "./SiteIcons";
import SiteMap from "./SiteMap";
import { getTravelledDistance, getApproximateDistanceFromRoute, getPlaceholderImage } from "../common/Utils";


function SiteImages({ images, shownImage, setShownImage }: { images: Array<string>, shownImage: number, setShownImage: (idx: number) => void }) {
	return (
		<>
			{images.map((image: string, idx: number) =>
				<Col className="mx-auto" xs={4} key={idx}>
					<div style={{ textAlign: "center" }}>
						<img onClick={() => setShownImage(idx)} className={`site-image-selector-image ${idx === shownImage && "site-image-selected"}`} src={image} />
					</div>
				</Col>
			)}
		</>
	);
}

// eslint-disable-next-line
function SiteDetails({ routeSite, uuid, onModify, onClose }: { routeSite?: RouteSiteProps, uuid?: string, onModify?: (site: SiteProps) => void, onClose: () => void }) {
	const { client } = useGlobalContext();
	const [site, setSite] = useState<SiteProps | null>(null);
	const [shownImage, setShownImage] = useState<number>(0);

	const [state, fetchAndSetSiteDetails] = useAsyncFn(async (uuid: string) => {
		const { data } = await client.get(`/sites/${uuid}/`);
		setSite(data);
	}, [])

	if (!site && !state.error && !state.loading && (uuid || routeSite && routeSite.site.uuid))
		fetchAndSetSiteDetails(uuid || (routeSite ? routeSite.site.uuid : ''));

	return (
		<Modal scrollable={true} fullscreen="md-down" className="bg-blue" size="lg" show={true} onHide={onClose}>
			{(!site || state.loading || state.error)
				?
				<Modal.Body className="text-center">
					<Spinner />
				</Modal.Body>
				:
				<>
					<Modal.Body>
						{/*onModify !== undefined &&
							<div className="site-details-modification-info">
								<h4>Haluatko muuttaa kohteen tietoja tai lisätä kuvia?</h4>
								<Button variant="info" onClick={() => { onModify(site); onClose(); }}>Ehdota muutoksia</Button>
							</div>
						*/}
						<Container className="text-center">
							<Row>
								{site
									?
									<>
										<h1>{site.name}</h1>
										<h5>{site.address.full}</h5>

										{routeSite &&
											<>
												<h6>
													<MdTimeline /> reitillä kuljettu matka {getTravelledDistance(routeSite.travelled_distance)} km <br />
													<MdAltRoute /> etäisyys reitiltä linnuntietä {getApproximateDistanceFromRoute(routeSite.distance_from_route)} km
												</h6>
											</>
										}
									</>
									:
									<h1>Reitti</h1>
								}
							</Row>
						</Container>
						<hr />
						<Container>
							{site &&
								<>
									<Row className="text-center">
										<Col xs="12" className="mx-auto">
											<span className="site-image-primary">
												{site.images && site.images.length > 0
													? <img src={site.images[shownImage]} />
													: <p style={{ fontSize: '10pt' }}>
														<span className="site-noimage">{getPlaceholderImage(site)}</span><br />
														Kohteelle ei toistaiseksi ole kuvaa.<br />
														Lisäämme uusia kuvia mahdollisuuksien mukaan.
													</p>
												}
											</span>
										</Col>
									</Row>
									{site.images && site.images.length > 1 &&
										<Row className="mt-3">
											<SiteImages images={site.images.slice(0, 3)} shownImage={shownImage} setShownImage={setShownImage} />
										</Row>
									}
									<Row className="mt-3">
										<SiteIcons site={site} size="normal" />
									</Row>
								</>
							}
							<Row>
								{site && <SiteMap site={site} />}
							</Row>
							{site &&
								<>
									<Row className="mt-3">
										<p>{site.description}</p>
									</Row>
									<Row className="mt-3">
										{site.urls && site.urls.map((url: string, idx: number) => {
											const url_ = url.startsWith('http') ? url : `http://${url}`
											return <p key={idx}>
												<>{(site.url_titles && site.url_titles[idx]) || "Lue lisää"}: &nbsp;</>
												<Link to={url} target="_blank" rel="noopener noreferrer">{(new URL(url_)).hostname}{(site.urls && site.urls.length > 1) ? ` [${idx + 1}]` : ""}</Link>
											</p>
										}

										)}
									</Row>
								</>
							}
						</Container>
					</Modal.Body>

					<Modal.Footer>
						<table style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td style={{ width: "90%" }}>
										{site && site.name}

										{/*!onModify &&
											<p style={{ fontSize: "8pt" }}>
												<Link to={`/kohteet/${site.uuid}`} target="_blank" rel="noopener noreferrer">
													Lisää kuva tai päivitä tietoja
												</Link>
											</p>
										*/}
									</td>
									<td style={{ width: "10%" }}>
										<Button variant="secondary" onClick={onClose}>Sulje</Button>
									</td>
								</tr>
							</tbody>
						</table>
					</Modal.Footer>
				</>
			}
		</Modal>
	);
}

export default SiteDetails;
