import Button from "react-bootstrap/Button";

function YesNo({onYes, onNo, size, yesText, noText}:{onYes: (() => void) | null, onNo: (() => void) | null, size?: string, yesText?: string, noText?: string}): JSX.Element {
	return (
		<div className="yesno">
			<Button variant="warning" size={size === "sm" ? "sm" : "lg"} style={{ float: 'left'}} onClick={() => {if (onNo) onNo()}} disabled={onNo === null}>
				{noText || "Peruuta"}
			</Button>
			<Button variant="success" size={size === "sm" ? "sm" : "lg"} style={{ float: 'right'}} onClick={() => {if (onYes) onYes()}} disabled={onYes === null}>
				{yesText || "OK"}
			</Button>
		</div>
	)
}

export default YesNo;
